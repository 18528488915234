import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Field } from "../models/field";
import { User } from "../models/user";

@Injectable({
    providedIn: 'root'
})
export class MapService {
    field: Field | undefined;

    constructor(private http: HttpClient) { }

    getUserCityPosition(user: User) {
        return new Promise((resolve, reject) => {
            this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + user.city + '&key=' + 'AIzaSyCmPlDORMlVGEok7iygcnpj9xv3v8AWBfo')
                .subscribe(
                    (data) => {
                        resolve(data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    goToField(field: Field) {
        this.field = field;
    }
}