<div class="container text-center white">
    <form action="createField()" [formGroup]="createForm">
        <div class="row">
            <div class="col-12 text-center">{{'FieldInfo.NewField'|translate|uppercase}}</div>
            <div class="col-12 mt-2">
                <input type="checkbox" id="foot" formControlName="foot" value="foot" hidden/>
                <label for="foot" [ngClass]="{'checked': createForm.get('foot')?.value==true}">{{'Home.Foot'|translate|uppercase}}</label>
                <input type="checkbox" id="basket" formControlName="basket" value="basket" hidden/>
                <label for="basket" [ngClass]="{'checked': createForm.get('basket')?.value==true}">{{'Home.Basket'|translate|uppercase}}</label>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 d-flex flex-wrap justify-content-center sides">
                <div class="col-4"><input type="radio" id="side2" formControlName="side" value="2" />
                    <label for="side2" [ngClass]="{'checked': createForm.get('side')?.value==2}">2v2</label>
                </div>
                <div class="col-4"><input type="radio" id="side3" formControlName="side" value="3" />
                    <label for="side3" [ngClass]="{'checked': createForm.get('side')?.value==3}">3v3</label>
                </div>
                <div class="col-4"><input type="radio" id="side5" formControlName="side" value="5" />
                    <label for="side5" [ngClass]="{'checked': createForm.get('side')?.value==5}">5v5</label>
                </div>
                <div class="col-4"><input type="radio" id="side7" formControlName="side" value="7" />
                    <label for="side7" [ngClass]="{'checked': createForm.get('side')?.value==7}">7v7</label>
                </div>
                <div class="col-4"><input type="radio" id="side8" formControlName="side" value="8" />
                    <label for="side8" [ngClass]="{'checked': createForm.get('side')?.value==8}">8v8</label>
                </div>
                <div class="col-4"><input type="radio" id="side11" formControlName="side" value="11" />
                    <label for="side11" [ngClass]="{'checked': createForm.get('side')?.value==11}">11v11</label>
                </div>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-12">
                <input type="text" id="name" formControlName="name" value="" placeholder="{{'FieldInfo.FieldName'|translate|uppercase}}" />
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn" type="button" (click)="filePicker.click()">{{'FieldInfo.AddImage'|translate|uppercase}}</button>
                <input hidden="true" type="file" #filePicker (change)="onImagePick($event)" id="photoUrl"
                    name="photoUrl">
            </div>
        </div>
        <div class="row m-3">
            <div class="col-12 image-preview" *ngIf="imagePreview">
                <div class="m-auto" style="background-image: url('{{imagePreview}}');
                                                                    background-size: cover; padding-top: 56.25%;">
                </div>
            </div>
        </div>
        <button (click)="createField()" class="btn" *ngIf="user?.pseudo"
            [disabled]="createForm.invalid">{{'FieldInfo.CreateField'|translate|uppercase}}</button>
        <button (click)="login()" class="btn" *ngIf="!user?.pseudo" >{{'Help.Log'|translate|uppercase}}</button>
    </form>
</div>
