import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  load = true;
  validLink = false;
  confirmed = false;
  email = '';

  constructor(private emailService: EmailService, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.emailService.isRecoveryLinkValid(window.location.pathname.substring(window.location.pathname.lastIndexOf('/'))).then((link: any) => {
      if (link.active) {
        this.validLink = true;
        this.email = link.email;
      };
    }).finally(() => this.load = false)
  }

  confirmAccount() {
    this.authService.confirmAccount(this.email).then((resp: any) => {
      this.confirmed = true;
    }).finally(() => this.load = false)
  }

  loginPage() {
    this.router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 50)
  }

}
