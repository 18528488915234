import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { LanguageComponent } from './language/language.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User | undefined;

  constructor(private authService: AuthService, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.authService.isAuth$.subscribe((res) => {
      if (res && !this.user) {
        this.setUser();
      }
    })
  }

  setUser() {
    this.user = this.authService.user;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  loginPage() {
    this.authService.lastUrl = window.location.pathname;
    this.router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 50)
  }

  translate() {
    this.dialog.open(LanguageComponent).afterClosed().subscribe(() => {
      this.dialog.closeAll();
    })
    // lng ? this.translateService.use(lng) : this.translateService.currentLang == 'en' ? this.translateService.use('es') : this.translateService.currentLang == 'es' ? this.translateService.use('fr') : this.translateService.use('en');
  }

}
