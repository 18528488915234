import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class BalanceService {
    constructor(private authService: AuthService) { }

    updateBalanceByPseudo(pseudo: string, credit: number) {
        return new Promise((resolve, reject) => {
            this.authService.getProfileByPseudo(pseudo).then((profile:any) => {
                this.authService.updateBalance(profile.balance+credit, profile._id).then((success) => {
                    resolve(success);
                }).catch((err) => {
                    reject(err);
                })
            }).catch((err) => {
                reject(err);
            })
        })
    }

}