import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Field } from "../models/field";

export class FieldsResponse {
    fields: Field[] = [];
    maxCount: number = 0;
}

@Injectable({
    providedIn: 'root'
})
export class FieldsService {
    fields: Field[] = [];
    currentField: Field | undefined;

    constructor(private http: HttpClient) { }

    getFields(neLat?: string, neLng?: string, swLat?: string, swLng?: string): Promise<FieldsResponse> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/fields/', {
                params: {
                    neLat: neLat ? neLat : 'null',
                    neLng: neLng ? neLng : 'null',
                    swLat: swLat ? swLat : 'null',
                    swLng: swLng ? swLng : 'null',
                }
            })
                .subscribe((fieldsResponse: FieldsResponse | any) => {
                    this.fields = fieldsResponse.fields;
                    resolve(fieldsResponse)
                }, (error) => {
                    console.log(error);
                    reject([])
                })
        });
    }

    getFieldById(id: string): Promise<Field> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/fields/' + id)
                .subscribe((field: any) => {
                    resolve(field)
                },
                    (error) => {
                        console.log(error);
                        reject(null)
                    });
        });
    }

    createField(field: Field, image: File | string | Blob) {
        return new Promise((resolve, reject) => {
            let fieldData = new FormData();
            fieldData.append('field', JSON.stringify(field));

            if (typeof image == 'string') {
                fetch(image).then((res) => {
                    res.blob().then((blob) => {
                        image = blob;
                        fieldData.append('image', image, field.name);
                        this.http.post(environment.protocol + '://' + environment.path + '/api/fields/', fieldData)
                            .subscribe(() => {
                                resolve(true)
                            },
                                () => {
                                    reject(false)
                                });
                    })
                })
            } else if (typeof image == 'undefined') {
                this.http.post(environment.protocol + '://' + environment.path + '/api/fields/', fieldData)
                    .subscribe(() => {
                        resolve(true)
                    },
                        () => {
                            reject(false)
                        });
            }

            else {
                fieldData.append('image', image, field.name);
                this.http.post(environment.protocol + '://' + environment.path + '/api/fields/', fieldData)
                    .subscribe(() => {
                        resolve(true)
                    },
                        () => {
                            reject(false)
                        });
            }
        });
    }

    setCurrentField(field: Field) {
        this.currentField = field;
    }


    // Field Management
    getFieldsBySearch(search: string): Promise<Field[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/fields/search/' + search.toLowerCase())
                .subscribe((fields: any) => {
                    resolve(fields)
                },
                    (error) => {
                        reject(false)
                    });
        })
    }

    getNLastFields(n: number) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/fields/display/' + n)
                .subscribe((fields) => {
                    resolve(fields)
                },
                    (error) => {
                        reject(false)
                    });
        })
    }

    updateField(field: Field, image?: Blob | string) {
        return new Promise((resolve, reject) => {
            let fieldData = new FormData();
            fieldData.append('field', JSON.stringify(field));
            if (typeof image == 'string') {
                fetch(image).then((res) => {
                    res.blob().then((blob) => {
                        image = blob;
                        fieldData.append('image', image, field.name)
                        this.http.put(environment.protocol + '://' + environment.path + '/api/fields/' + field._id, fieldData)
                            .subscribe(() => { resolve(true) }, () => { reject(false) });
                    })
                })
            } else {
                if (image) {
                    fieldData.append('image', image, field.name)
                    this.http.put(environment.protocol + '://' + environment.path + '/api/fields/' + field._id, fieldData)
                        .subscribe(() => { resolve(true) }, () => { reject(false) });
                } else {
                    this.http.put(environment.protocol + '://' + environment.path + '/api/fields/' + field._id, fieldData)
                        .subscribe(() => { resolve(true) }, () => { reject(false) });
                }
            }
        })
    }

    deleteField(field: Field) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.protocol + '://' + environment.path + '/api/fields/' + field._id)
                .subscribe(() => {
                    resolve(true)
                },
                    (error) => {
                        reject(false)
                    });
        })
    }
}