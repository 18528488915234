<!-- <img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg1">
<img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg2"> -->

<div class="container text-center" *ngIf="profile">
    <app-card pseudo="{{profile.userPseudo}}" *ngIf="!reload && !editProfile"></app-card>

    <!-- <div *ngIf="profile.description">{{profile.description|uppercase}}</div> -->
    <div *ngIf="user && profile.userPseudo==user.pseudo && !editProfile" class="d-flex justify-content-center align-items-center">
        {{'Profile.YaMatchCredits'|translate|uppercase}}: <span style="font-size: x-large;"
            class="mx-2">{{profile.balance}}</span>
    </div>
    <div *ngIf="user && profile.userPseudo==user.pseudo && !editProfile">
        <a routerLink="/addCoins/{{profile.id}}" title="{{'Profile.BuyCredits'|translate|uppercase}}">
            <button class="btn">
                {{'Profile.BuyCredits'|translate|uppercase}}
            </button>
        </a>
    </div>
    <div *ngIf="user && !editProfile" class="d-flex justify-content-around mx-auto my-3" style="max-width: 350px;">
        <button class="btn" (click)="editProfileFn()"
            *ngIf="profile.userPseudo==user.pseudo">{{'Profile.Edit'|translate|uppercase}}
            {{'Header.Profile'|translate|uppercase}}</button>
        <button class="btn mx-1" (click)="contact()"
            *ngIf="profile.userPseudo!=user.pseudo">{{'Profile.Contact'|translate|uppercase}}</button>
        <button class="btn mx-1" (click)="report()"
            *ngIf="profile.userPseudo!=user.pseudo">{{'Profile.Report'|translate|uppercase}}</button>
    </div>
    <div *ngIf="editProfile" style="margin-top: 2rem;">
        <form action="updateProfile()" [formGroup]="profileForm" class="my-3 mx-auto" style="width: 300px;">
            <div>
                <div>
                    <button class="btn btn-success" type="button"
                        (click)="filePicker.click()">{{'FieldInfo.AddImage'|translate|uppercase}}</button>
                    <input hidden="true" type="file" #filePicker (change)="onImagePick($event)" id="photoUrl"
                        name="photoUrl">
                </div>
            </div>
            <div class="my-3 image-preview mx-auto" *ngIf="imagePreview" style="width: 220px">
                <div style="background-image: url('{{imagePreview}}');
                        background-size: cover; padding-top: 100%; border-radius: 150px;">
                </div>
            </div>
            <div class="formRow">
                <label for="city">{{'Home.City'|translate}}: </label>
                <input type="text" id="city" formControlName="city">
                <!-- <select id="city" formControlName="city">
                    <option *ngFor="let age of array(12, 100)" value="{{age}}">{{age}}</option>
                </select> -->
            </div>
            <div class="formRow">
                <label for="age">{{'Profile.Age'|translate}}: </label>
                <select id="age" formControlName="age">
                    <option *ngFor="let age of array(12, 100)" value="{{age}}">{{age}}</option>
                </select>
            </div>

            <div class="formRow">
                <label for="foot">{{'Profile.FootLvl'|translate}}: </label>
                <select id="foot" formControlName="foot">
                    <option value="1">{{'Home.Lvl1'|translate}}</option>
                    <option value="2">{{'Home.Lvl2'|translate}}</option>
                    <option value="3">{{'Home.Lvl3'|translate}}</option>
                </select>
            </div>
            <div class="formRow">
                <label for="basket">{{'Profile.BasketLvl'|translate}}: </label>
                <select id="basket" formControlName="basket">
                    <option value="1">{{'Home.Lvl1'|translate}}</option>
                    <option value="2">{{'Home.Lvl2'|translate}}</option>
                    <option value="3">{{'Home.Lvl3'|translate}}</option>
                </select>
            </div>
            <div class="formRow">
                <label for="favoritePos">{{'Profile.FavPos'|translate}}: </label>
                <select id="favoritePos" formControlName="favoritePos">
                    <option *ngFor="let pos of ['GK', 'DEF', 'MID', 'ATT']" value="{{pos}}">{{pos}}</option>
                </select>
            </div>
            <button class="btn m-3" (click)="updateProfile()" [disabled]="updating">{{'Profile.Update'|translate|uppercase}}</button><button
                class="btn m-3" (click)="editProfile=false">{{'Profile.Cancel'|translate|uppercase}}</button>
        </form>
    </div>
    <div class="row" *ngIf="displayContactForm">
        <form action="" [formGroup]="contactForm">
            <div class="col-12">
                <input type="text" formControlName="subject" name="subject"
                    placeholder="{{'Profile.Subject'|translate}}" style="width: 320px;">
            </div>
            <div class="col-12 my-3">
                <textarea type="text" rows="5" formControlName="messageContent" name="messageContent"
                    placeholder="{{'Profile.Message'|translate}}" style="width: 320px;">
                    </textarea>
            </div>
            <div>
                <button class="btn" [disabled]="contactForm.invalid"
                    (click)="sendContactMessage()">{{'Profile.SendTo'|translate|uppercase}}
                    {{profile.userPseudo}}</button>
            </div>
            <div>
                <button class="btn mt-3" (click)="displayContactForm=!displayContactForm">RETOUR</button>
            </div>
        </form>
    </div>
    <div class="row" *ngIf="displayReportForm">
        <form action="" [formGroup]="reportForm">
            <div class="col-12">
                <select type="text" formControlName="subject" name="subject" style="width: 320px;">
                    <option value="absent">{{'Profile.Absent'|translate}}</option>
                    <option value="rude">{{'Profile.Rude'|translate}}</option>
                    <option value="fake">{{'Profile.Fake'|translate}}
                    </option>
                    <option value="other">{{'Profile.Other'|translate}}</option>
                </select>
            </div>
            <div class="col-12 my-3">
                <textarea type="text" rows="5" formControlName="messageContent" name="messageContent"
                    placeholder="{{'Profile.TellUsMore'|translate}}" style="width: 320px;">
                    </textarea>
            </div>
            <div>
                <button class="btn" [disabled]="reportForm.invalid"
                    (click)="sendReport()">{{'Profile.Report'|translate|uppercase}}
                    {{profile.userPseudo}}</button>
            </div>
            <div>
                <button class="btn mt-3" (click)="displayReportForm=!displayReportForm">RETOUR</button>
            </div>
        </form>
    </div>
    <div class="row text-center" *ngIf="messageSuccess">
        {{'Profile.WellReceived'|translate|uppercase}}
    </div>
</div>
<div *ngIf="loading" class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-5">{{'Logs.Loading'|translate|uppercase}}</div>
</div>
<div *ngIf="!loading && !profile" class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-5">ERROR</div>
    <button class="btn" routerLink="/mygames">{{'Logs.Back'|translate|uppercase}}</button>
</div>