export class User {
    id!: string;
    pseudo!: string;
    email!: string;
    token!: string;
    city!: string;
    footballLevel!: number;
    basketballLevel!: number;
    profile!: string;
    creationDate!: Date;
    modificationDate!: Date;
    active!: boolean;
}