<div class="container">
    <div *ngIf="!subscribeSuccess">
        <p class="text-center mt-5 mx-1">{{'Home.Signup'|translate|uppercase}}</p>
        <form action="onSignup()" [formGroup]="form">
            <div class="row my-3">
                <input class="col-12" id="pseudo" type="text" placeholder="{{'Home.Pseudo'|translate}}*"
                    [ngClass]="{'invalid': form.get('pseudo')?.invalid&&form.get('pseudo')?.dirty&&form.get('pseudo')?.value}"
                    formControlName="pseudo" name="pseudo" (keyup)="cleanPseudoValue($event)" />
                <div class="errorMsg"
                    *ngIf="form.get('pseudo')?.invalid&&form.get('pseudo')?.dirty&&form.get('pseudo')?.value">
                    Char alpha numeric only (15max)
                </div>
            </div>
            <div class="row my-3">
                <input class="col-12" id="email" type="email" placeholder="{{'Home.Email'|translate}}*"
                    [ngClass]="{'invalid': form.get('email')?.invalid&&form.get('email')?.dirty&&form.get('email')?.value}"
                    formControlName="email" name="email" />
            </div>
            <div class="row my-3">
                <input class="col-12" id="password" type="password" placeholder="{{'Home.Password'|translate}}*"
                    formControlName="password" name="password"
                    [ngClass]="{'invalid': form.get('password')?.invalid&&form.get('password')?.dirty&&form.get('password')?.value}" />
                <div class="errorMsg"
                    *ngIf="form.get('password')?.invalid&&form.get('password')?.dirty&&form.get('password')?.value">
                    6 char minimum (50max)
                </div>
            </div>
            <div class="row my-3">
                <input class="col-12" id="confirmPassword" type="password"
                    placeholder="{{'Home.ConfirmPassword'|translate}}*" formControlName="confirmPassword"
                    [ngClass]="{'invalid': form.get('password')?.value!=form.get('confirmPassword')?.value&&form.get('confirmPassword')?.dirty}"
                    name="confirmPassword" />
            </div>
            <div class="row my-3">
                <input class="col-12" id="city" type="text" placeholder="{{'Home.City'|translate}}*"
                    [ngClass]="{'invalid': form.get('city')?.invalid&&form.get('city')?.dirty&&form.get('city')?.value}"
                    formControlName="city" name="city" />
                <div class="errorMsg"
                    *ngIf="form.get('city')?.invalid&&form.get('city')?.dirty&&form.get('city')?.value">
                    2 char minimum (25max)
                </div>
            </div>
            <div *ngIf="errorMessage" class="text-center">
                <p>{{errorMessage|uppercase}}</p>
            </div>

        </form>
        <div class="row my-3" class="cgu">
            <p class="text-start">{{'CGU.Accept'|translate}}: <br />
                <span (click)="open('cgu')">- {{'CGU.title'|translate}}</span><br />
                <span (click)="open('cgv')">- {{'CGV.title'|translate}}</span><br />
                <span (click)="open('cookies')">- {{'Cookies.title'|translate}}</span>
            </p>
            <button class="btn btn-dark w-100" (click)="onSignup()"
                [disabled]="form.invalid || (form.get('password')?.value != form.get('confirmPassword')?.value)">{{'Home.Signup'|translate|uppercase}}</button>
        </div>
    </div>
    <div *ngIf="subscribeSuccess" class="text-center mt-5">
        <p>{{'Logs.Confirm'|translate|uppercase}}</p>
    </div>
</div>