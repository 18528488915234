import { Component, Input, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  profile: Profile | undefined;
  user: User | undefined;

  @Input()
  pseudo: string | undefined;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    if (this.pseudo) {
      this.authService.getProfileByPseudo(this.pseudo).then((profile: any) => this.profile = profile);
      this.authService.getUserByPseudo(this.pseudo).then((user: any) => this.user = user);
    }
  }

  array(start: number|undefined, end: number|undefined) {
    if (start === end) return [start];
    let arr = [];
    if (start&&end)
    for (let i = start; i <= end; i++) {
      arr.push(i)
    }
    return arr;
  }

}
