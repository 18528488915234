import { } from "googlemaps"

export class Field {
    _id!: string;
    sport!: string[];
    side!: number;
    name!: string;
    address!: string;
    lat!: number;
    lng!: number;
    city!: string;
    photoUrl!: string;
    status!: string;
    creationUser!: string;
    creationDate!: Date;
    modificationDate!: Date;
    active!: boolean;

    constructor(sport: string[], side: number, name: string, address: string, latitude: number, longitude: number, city:string, photoUrl:string, status: string, creationUser: string, creationDate: Date, modificationDate: Date, active: boolean) {
        this.sport = sport;
        this.side = side;
        this.name = name;
        this.address = address;
        this.lat = latitude;
        this.lng = longitude;
        this.city = city;
        this.photoUrl = photoUrl;
        this.status = status;
        this.creationUser = creationUser;
        this.creationDate = creationDate;
        this.modificationDate = modificationDate;
        this.active = active;
    }
}