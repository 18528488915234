<div *ngIf="gameSelected && fieldSelected && teamSide" class="text-center mt-5">
    <div
        *ngIf="user && !(gameSelected.isPublic || gameSelected.playersId?.includes(user.id) || gameSelected.admin==user.id || gameSelected.gamePassword===pwValue)">
        <div class="container text-center">
            <input type="text" style="max-width: 300px;border-radius:2rem; padding: 0.5rem;" [(ngModel)]="pwValue"
                placeholder="{{'MyGames.GamePassword'|translate|uppercase}}">
        </div>
    </div>
    <div
        *ngIf="user && (gameSelected.isPublic || gameSelected.playersId?.includes(user.id) || gameSelected.admin==user.id || gameSelected.gamePassword===pwValue)">
        <div class="row my-2">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto" style="font-size: 1.5rem">
                {{fieldSelected?.name|uppercase}}</div>
        </div>
        <div class="row my-2">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">{{fieldSelected?.address|uppercase}},
                {{fieldSelected?.city|uppercase}}</div>
        </div>
        <div class="row px-3 mx-auto mt-2" style="max-width:300px;">
            <button class="btn" (click)="goTo(fieldSelected)">{{'MyGames.GoTo'|translate|uppercase}}</button>
        </div>
        <div class="row my-2">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto px-3">
                <div class="row">
                    <div class="col-6 col-sm-3">{{gameSelected.sport|uppercase}}</div>
                    <div class="col-6 col-sm-3">{{gameSelected.side}}v{{gameSelected.side}}</div>
                    <div class="col-6 col-sm-3" (click)="editStart()"
                        [ngClass]="{'gameTime': gameSelected?.admin===user?.id}">
                        {{gameSelected.start|date:'dd/MM'}} -
                        {{gameSelected.start|date:'HH:mm'}}</div>
                    <div class="col-6 col-sm-3" *ngIf="gameSelected.isPublic" (click)="editPublic()"
                        [ngClass]="{'gameStatus': gameSelected?.admin===user?.id}">
                        {{'FieldInfo.Public'|translate|uppercase}}</div>
                    <div class="col-6 col-sm-3" *ngIf="!gameSelected.isPublic" (click)="editPublic()"
                        [ngClass]="{'gameStatus': gameSelected?.admin===user?.id}">
                        {{'FieldInfo.Private'|translate|uppercase}}</div>
                </div>
                <div class="row px-3 mx-auto mt-2" style="max-width:300px;"
                    *ngIf="!gameSelected?.isPublic && gameSelected?.admin==user?.id">
                    <button class="btn" *ngIf="!modifyPw"
                        (click)="modifyPw=true">{{'MyGames.ModifyPass'|translate|uppercase}}</button>
                    <input type="text" *ngIf="modifyPw" [(ngModel)]="pwValue" style="border-radius:2rem;padding:0.5rem;"
                        placeholder="{{'MyGames.NoGamePassword'|translate|uppercase}}">
                    <button class="btn mt-2" *ngIf="modifyPw"
                        (click)="editGamePw(gameSelected)">{{'MyGames.Confirm'|translate|uppercase}}</button>
                </div>
            </div>
        </div>
        <div class="row my-2 text-center d-flex flex-column" *ngIf="user && gameSelected.admin==user.id && newStart">
            <div>{{'MyGames.PickNewTime'|translate|uppercase}}</div>
            <div>
                <input type="datetime-local" [(ngModel)]="newStartTime" min="now"
                    style="width:300px;border-radius:2rem;padding:0.5rem;">
            </div>
            <div>
                <button class="btn my-2" style="width: 300px;"
                    (click)="newStartingTime()">{{'MyGames.NewStart'|translate|uppercase}}</button>
            </div>
        </div>
        <div class="row my-2 text-center">
            <div>
                <p>{{'MyGames.GetIn'|translate|uppercase}}: <span
                        *ngIf="gameSelected.price==0">{{'MyGames.Free'|translate|uppercase}}</span><span
                        *ngIf="gameSelected.price!=0">{{gameSelected.price}}{{gameSelected.currency}}</span></p>
            </div>
        </div>
        <div class="row my-2">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto px-3">
                <!-- <div class="w-100 m-auto fieldImg" style="background-image: url('{{fieldSelected?.photoUrl}}');
                                                                background-size: cover; padding-top: 56.25%;">
                </div> -->
                <app-image-with-loading [image]="fieldSelected?.photoUrl">
                </app-image-with-loading>
            </div>
        </div>
        <div class="row my-2" *ngIf="user && !lastAdmin">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto">
                <div class="row px-3">
                    <div class="col-5">
                        <p class="teamName">{{'MyGames.BrightTeam'|translate|uppercase}}</p>
                        <ul class="p-0">
                            <li *ngFor="let slot of teamSide, let i = index">
                                <div *ngIf="gameSelected.players[0][i]" class="d-flex teamList">
                                    <span class="ball"
                                        [ngClass]="{'gotBall': gameSelected.gotBall.includes(gameSelected.players[0][i]),'myBall': user.pseudo==gameSelected.players[0][i]}"
                                        *ngIf="finished" (click)="editGotBall(gameSelected.players[0][i])">
                                        <img src="../../assets/icons/{{gameSelected.sport}}ball.png"
                                            alt="{{gameSelected.sport}}" style="width: 24px; cursor: pointer;" />
                                    </span>
                                    <span class="profile"
                                        routerLink="/profile/{{gameSelected.players[0][i]}}">{{gameSelected.players[0][i]}}</span>
                                    <span *ngFor="let item of array(levels.get(gameSelected.players[0][i]))"
                                        class="stars">
                                        <img src="../../assets/icons/etoile.png" class="star"
                                            alt="level: {{levels.get(gameSelected.players[0][i])}}"
                                            style="width: 16px;" />
                                    </span>
                                    <span
                                        *ngIf="(gameSelected.players[0][i]==user.pseudo || gameSelected.admin==user.id) && finished"
                                        (click)="cancel(gameSelected, gameSelected.players[0][i])"
                                        style="cursor: pointer;"><img src="../../assets/icons/delete.svg"
                                            alt="{{'MyGames.Leave'|translate|uppercase}}"></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-5 d-flex flex-column">
                        <p class="teamName text-end">{{'MyGames.DarkTeam'|translate|uppercase}}</p>
                        <ul class="p-0">
                            <li *ngFor="let slot of teamSide, let i = index">
                                <div *ngIf="gameSelected.players[1][i]" class="d-flex teamList justify-content-end">
                                    <span
                                        *ngIf="(gameSelected.players[1][i]==user.pseudo || gameSelected.admin==user.id) && finished"
                                        (click)="cancel(gameSelected, gameSelected.players[1][i])"
                                        style="cursor: pointer;"><img src="../../assets/icons/delete.svg"
                                            alt="{{'MyGames.Leave'|translate|uppercase}}"></span>
                                    <span *ngFor="let item of array(levels.get(gameSelected.players[1][i]))"
                                        class="stars">
                                        <img src="../../assets/icons/etoile.png" class="star"
                                            alt="level: {{levels.get(gameSelected.players[1][i])}}"
                                            style="width: 16px;" />
                                    </span>
                                    <span class="profile"
                                        routerLink="/profile/{{gameSelected.players[1][i]}}">{{gameSelected.players[1][i]}}</span>
                                    <span class="ball"
                                        [ngClass]="{'gotBall': gameSelected.gotBall.includes(gameSelected.players[1][i]),'myBall': user.pseudo==gameSelected.players[1][i]}"
                                        *ngIf="finished" (click)="editGotBall(gameSelected.players[1][i])">
                                        <img src="../../assets/icons/{{gameSelected.sport}}ball.png"
                                            alt="{{gameSelected.sport}}" style="width: 24px; cursor: pointer;" />
                                    </span>

                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <button *ngIf="!gameSelected.playersId.includes(user.id) && gameSelected.status!='complete'"
                            class="btn" [disabled]="gameSelected.players[0][gameSelected.side-1]"
                            (click)="joinGame(gameSelected, 0, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button>
                    </div>
                    <div class="col-6">
                        <button *ngIf="!gameSelected.playersId.includes(user.id) && gameSelected.status!='complete'"
                            class="btn" [disabled]="gameSelected.players[1][gameSelected.side-1]"
                            (click)="joinGame(gameSelected, 1, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button>
                    </div>
                </div>
                <div class="row px-3 mx-auto mt-5"
                    *ngIf="gameSelected.playersId.length!=gameSelected.side*2 && gameSelected.status!='complete'"
                    (click)="openInvite()" style="max-width:300px;">
                    <button class="btn" *ngIf="!copied">{{'MyGames.Invite'|translate|uppercase}}</button>
                    <button [disabled]="copied" class="btn"
                        *ngIf="copied">{{'MyGames.Copied'|translate|uppercase}}</button>
                </div>
                <div class="row px-3 mx-auto mt-2"
                    *ngIf="gameSelected.admin==user.id && gameSelected.status!='complete'" (click)="openSwitch()"
                    style="max-width:300px;">
                    <a class="btn">{{'MyGames.Switch'|translate|uppercase}}</a>
                </div>
                <div class="row px-3 mt-2">
                    <div class="row px-3 mx-auto" style="max-width:300px;" (click)="activeChat(gameSelected)"
                        *ngIf="gameSelected.admin==user.id">
                        <a class="btn"
                            *ngIf="!gameSelected.activeChat">{{'MyGames.ActivateChat'|translate|uppercase}}</a>
                        <a class="btn"
                            *ngIf="gameSelected.activeChat">{{'MyGames.DesactivateChat'|translate|uppercase}}</a>
                    </div>
                    <div *ngIf="chatDoc && gameSelected.activeChat">
                        <div class="dark col-12 my-2" id="chat"
                            style="height: 400px;background-color: white;overflow-y: auto;border-radius: 2rem;padding: 1rem;">
                            <div *ngIf="chatDoc.content.length==0" class="row d-flex justify-content-center">
                                {{'MyGames.NoDialog'|translate|uppercase}}
                            </div>
                            <div class="row" *ngFor="let item of chatDoc.content; let i = index">
                                <div *ngIf="item?.visible" class="col-12">
                                    <div class="d-flex"
                                        *ngIf="i==0 || (chatDoc.content[i-1] && chatDoc.content[i-1]?.sender!=item?.sender)">
                                        <div class="timestamp">{{item.date|date:'dd/MM'}}-{{item.date|date:'HH:mm'}}
                                        </div>
                                        <div class="sender" routerLink="/profile/{{item.sender}}">{{item.sender}} :
                                        </div>
                                    </div>
                                    <div class="msg text-start">
                                        <p class="m-0;">{{item.msg}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex newMsg">
                            <input placeholder="{{'MyGames.WriteHere'|translate}}" type="text" [(ngModel)]="newMsg"
                                style="width: inherit;border-radius: 5rem;padding: 0.5rem;" (keyup.enter)="sendMsg()">
                            <button class="btn mx-2" (click)="sendMsg()"
                                [disabled]="(newMsg.length>100)||(newMsg.length==0)">{{'MyGames.Send'|translate|uppercase}}</button>
                            <button class="btn" (click)="resetChat(chatDoc)"
                                *ngIf="gameSelected.admin==user.id">{{'MyGames.ResetChat'|translate|uppercase}}</button>
                        </div>
                    </div>
                </div>
                <div class="row px-3 mx-auto mt-2"
                    *ngIf="gameSelected.admin==user.id && gameSelected.status!='complete' && !cantDelete"
                    (click)="deleteGame(gameSelected)" style="max-width:300px;">
                    <a class="btn">{{'MyGames.Delete'|translate|uppercase}}</a>
                </div>
                <div class="row p-3" *ngIf="gameSelected.admin==user.id && gameSelected.status!='complete'">
                    <a class="btn"
                        (click)="closeAndConfirm(gameSelected)">{{'MyGames.CloseAndConfirm'|translate|uppercase}}</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!user">
        {{'MyGames.ShouldConnect2'|translate|uppercase}}
    </div>
</div>
<div *ngIf="loading" class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-5">{{'Logs.Loading'|translate|uppercase}}</div>
</div>
<div *ngIf="!loading && !gameSelected" class="d-flex flex-column justify-content-center align-items-center">
    <div class="mt-5">{{'MyGames.GameDoesntExist'|translate|uppercase}}</div>
    <button class="btn" routerLink="/mygames">{{'Logs.Back'|translate|uppercase}}</button>
</div>