<div *ngIf="!field">Error</div>
<div *ngIf="field" class="d-flex flex-column justify-content-center white"
    style="font-size: 0.7rem;">
    <div class="row text-center">
        <h2 class="white">{{field.name|uppercase}}</h2>
    </div>
    <div class="row text-center">

        <div class="col-12 image-preview" *ngIf="field.photoUrl">
            <app-image-with-loading [image]="field.photoUrl" [sport]="field.sport">
            </app-image-with-loading>
        </div>
    </div>
    <div class="row text-center">
        <p>{{field.address|uppercase}}, {{field.city|uppercase}}</p>
    </div>
    <div class="row text-center">
        <p>MAX: {{field.side}}v{{field.side}}</p>
    </div>
    <div class="row warning mb-1" *ngIf="field.status.includes('crowded')">
        <p class="m-0"><img class="mx-1" src="../../assets/icons/warning.svg" alt="WARN">{{'FieldInfo.CrowdedWarning'|translate|uppercase}}</p>
    </div>
    <div class="row danger mb-1" *ngIf="field.status.includes('private')">
        <p class="m-0"><img class="mx-1" src="../../assets/icons/danger.svg" alt="DANGER">{{'FieldInfo.PrivateDanger'|translate|uppercase}}</p>
    </div>
    <div *ngIf="!creatingGame && !gameCreated && !gameSelected">
        <div *ngIf="games" class="text-center"> 
            <div>
                {{'FieldInfo.PublicGames'|translate|uppercase}}: {{games.length}}
            </div>
            <div class="row" *ngFor="let game of games" (click)="selectGame(game)" style="cursor: pointer;">
                <div class="col-3">{{game.sport|uppercase}}</div>
                <div class="col-2">{{game.side}}v{{game.side}}</div>
                <div class="col-3">{{game.date|date:'dd/MM'}}</div>
                <div class="col-3">{{game.start|date:'HH:mm'}}</div>
                <td class="col-1 p-0" *ngIf="game.side*2-game.playersId.length == 0">{{'FULL'|translate|uppercase}}
                </td>
                <td class="col-1 p-0" *ngIf="game.side*2-game.playersId.length != 0">
                    {{game.side*2-game.playersId.length}}</td>
            </div>
        </div>
        <div class="row text-center mt-3">
            <div class="btn m-auto" (click)="newGame()">
                {{'FieldInfo.NewGame'|translate|uppercase}}
            </div>
        </div>
    </div>
    <div class="row text-center" *ngIf="gameCreated">
        <p>{{'FieldInfo.CreationGameSuccess'|translate|uppercase}} {{'Header.MyGames'|translate|uppercase}}</p>
        <button class="btn" (click)="backInfo()">{{'FieldInfo.BackInfo'|translate|uppercase}}</button>
    </div>
    <div class="row text-center" *ngIf="gameSelected">
        <div *ngIf="!user">
            <div class="col">{{'FieldInfo.PleaseConnect'|translate|uppercase}}</div>
            <app-login (isAuth)="authEvent($event)"></app-login>
        </div>
        <div *ngIf="user">
            <div class="row gameInfo mb-3">
                <div class="col-3">{{gameSelected.sport|uppercase}}</div>
                <div class="col-3">{{gameSelected.side}}v{{gameSelected.side}}</div>
                <div class="col-3">{{gameSelected.date|date:'dd/MM'}}</div>
                <div class="col-3">{{gameSelected.start|date:'HH:mm'}}</div>
            </div>
            <div class="row playerList">
                <div class="col-6 text-start">
                    <p>{{'MyGames.BrightTeam'|translate|uppercase}}</p>
                    <ul class="p-0">
                        <li *ngFor="let slot of teamSide, let i = index">
                            <p *ngIf="gameSelected.players[0][i]" style="font-size: small;">
                                {{gameSelected.players[0][i]}}</p>
                            <!-- <button *ngIf="!gameSelected.players[0][i] && ! cantJoin" class="btn"
                                (click)="joinGame(gameSelected, 0, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button> -->
                        </li>
                    </ul>
                </div>
                <div class="col-6 text-end">
                    <p>{{'MyGames.DarkTeam'|translate|uppercase}}</p>
                    <ul class="p-0">
                        <li *ngFor="let slot of teamSide, let i = index">
                            <p *ngIf="gameSelected.players[1][i]" style="font-size: small;">
                                {{gameSelected.players[1][i]}}</p>
                            <!-- <button *ngIf="!gameSelected.players[1][i] && !cantJoin" class="btn"
                                (click)="joinGame(gameSelected, 1, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button> -->
                        </li>
                    </ul>
                </div>
                <div class="col-12 d-flex justify-content-between">
                    <div class="col-5">
                        <button *ngIf="!cantJoin && gameSelected.status!='complete'" class="btn"
                            [disabled]="gameSelected.players[0][gameSelected.side-1]"
                            (click)="joinGame(gameSelected, 0, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button>
                    </div>
                    <div class="col-5">
                        <button *ngIf="!cantJoin && gameSelected.status!='complete'" class="btn"
                            [disabled]="gameSelected.players[1][gameSelected.side-1]"
                            (click)="joinGame(gameSelected, 1, user.pseudo, user.id)">{{'MyGames.Join'|translate|uppercase}}</button>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <a routerLink="game/{{gameSelected._id}}" class="secondary btn" (click)="closeDialog()"
                        style="font-size: large;">{{'FieldInfo.GamePage'|translate|uppercase}}</a>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="creatingGame && lowBalance" class="row mt-2">
        <p>{{'FieldInfo.NoCredit'|translate|uppercase}}</p>
        <button class="btn mb-1" (click)="lowBalance=!lowBalance">{{'FieldInfo.CreateFreeGame'|translate|uppercase}}</button>
        <a routerLink="/profile/{{user?.pseudo}}"><button class="btn"
                (click)="closeDialog()">{{'FieldInfo.BuyCredits'|translate|uppercase}}</button></a>
    </div>
    <div *ngIf="creatingGame && !lowBalance" class="row">
        <form action="createGame()" [formGroup]="form">
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <input *ngIf="field.sport.includes('foot')" type="radio" id="foot" formControlName="sport"
                        value="foot" />
                    <label *ngIf="field.sport.includes('foot')"
                        [ngClass]="{'checked': form.get('sport')?.value=='foot'}"
                        for="foot">{{'Home.Foot'|translate|uppercase}}</label>
                    <input *ngIf="field.sport.includes('basket')" type="radio" id="basket" formControlName="sport"
                        value="basket" />
                    <label *ngIf="field.sport.includes('basket')"
                        [ngClass]="{'checked': form.get('sport')?.value=='basket'}"
                        for="basket">{{'Home.Basket'|translate|uppercase}}</label>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <input type="radio" id="lvl1" formControlName="level" value="1" />
                    <label for="lvl1"
                        [ngClass]="{'checked': form.get('level')?.value==1}">{{'Home.Lvl1'|translate|uppercase}}</label>
                    <input type="radio" id="lvl2" formControlName="level" value="2" />
                    <label for="lvl2"
                        [ngClass]="{'checked': form.get('level')?.value==2}">{{'Home.Lvl2'|translate|uppercase}}</label>
                    <input type="radio" id="lvl3" formControlName="level" value="3" />
                    <label for="lvl3"
                        [ngClass]="{'checked': form.get('level')?.value==3}">{{'Home.Lvl3'|translate|uppercase}}</label>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <input type="radio" id="isPublic" formControlName="isPublic" value="1" />
                    <label for="isPublic"
                        [ngClass]="{'checked': form.get('isPublic')?.value==1}">{{'FieldInfo.Public'|translate|uppercase}}</label>
                    <input type="radio" id="isntPublic" formControlName="isPublic" value="2" />
                    <label for="isntPublic"
                        [ngClass]="{'checked': form.get('isPublic')?.value==2}">{{'FieldInfo.Private'|translate|uppercase}}</label>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center align-items-center">
                    <div [hidden]="2>field.side"><input type="radio" id="side2" formControlName="side" value="2" />
                        <label for="side2" [ngClass]="{'checked': form.get('side')?.value==2}">2v2</label>
                    </div>
                    <div [hidden]="3>field.side"><input type="radio" id="side3" formControlName="side" value="3" />
                        <label for="side3" [ngClass]="{'checked': form.get('side')?.value==3}">3v3</label>
                    </div>
                    <div [hidden]="5>field.side"><input type="radio" id="side5" formControlName="side" value="5" />
                        <label for="side5" [ngClass]="{'checked': form.get('side')?.value==5}">5v5</label>
                    </div>
                    <div [hidden]="7>field.side || !field.sport.includes('foot')"><input type="radio" id="side7"
                            formControlName="side" value="7" />
                        <label for="side7" [ngClass]="{'checked': form.get('side')?.value==7}">7v7</label>
                    </div>
                    <div [hidden]="8>field.side || !field.sport.includes('foot')"><input type="radio" id="side8"
                            formControlName="side" value="8" />
                        <label for="side8" [ngClass]="{'checked': form.get('side')?.value==8}">8v8</label>
                    </div>
                    <div [hidden]="11>field.side || !field.sport.includes('foot')"><input type="radio" id="side11"
                            formControlName="side" value="11" />
                        <label for="side11" [ngClass]="{'checked': form.get('side')?.value==11}">11v11</label>
                    </div>
                </div>
            </div>
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <input type="datetime-local" id="date" formControlName="date" value="" [min]="now" />
                </div>
            </div>
            <div class="row my-2 text-center">
                <div class="d-flex justify-content-center">
                    <label [ngClass]="{'checked': freeGame}" for="freeGame"
                        (click)="setFreeGame(1)">{{'FieldInfo.FreeGame'|translate|uppercase}}</label>
                    <label [ngClass]="{'checked': !freeGame}" for="notFreeGame"
                        (click)="setFreeGame(0)">{{'FieldInfo.PayToPlay'|translate|uppercase}}</label>
                </div>
                <div *ngIf="!freeGame" class="col-8 d-flex justify-content-center mt-2 mx-auto">
                    <div class="col-6">
                        <input type="number" id="price" formControlName="price" value="0" [min]="0" placeholder="Price"
                            style="height: 30px; width: 70px;" />
                    </div>
                    <div class="col-6">
                        <select formControlName="currency" id="currency" style="height: 30px;">
                            <option value="EUR" selected>EUR - €</option>
                            <option value="USD">USD - $</option>
                            <option value="GPB">GPB - £</option>
                            <option value="MXN">MXN - $</option>
                            <option value="CAD">CAD - $</option>
                        </select>
                    </div>
                </div>

                <!-- <div>
                    <button class="btn" (click)="setFreeGame()">Partie <span *ngIf="freeGame">Gratuite</span><span *ngIf="!freeGame">Payante</span></button>
                </div>
                <div *ngIf="!freeGame">
                    <div class="col-8 d-flex justify-content-center">
                        <input type="number" id="price" formControlName="price" value="0" [min]="0" style="width:50px"/>
                    </div>
                    <div class="col-4 d-flex justify-content-center">

                        <select formControlName="currency" id="currency">
                            <option value="EUR" checked>EUR - €</option>
                            <option value="USD">USD - $</option>
                            <option value="GPB">GPB - £</option>
                            <option value="MXN">MXN - $</option>
                            <option value="CAD">CAD - $</option>
                        </select>
                    </div>
                </div> -->
            </div>
            <!-- <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <textarea formControlName="description" id="description" cols="30" rows="10"
                        placeholder="Description"></textarea>
                </div>
            </div> -->
            <div class="row my-2">
                <div class="col-12 d-flex justify-content-center">
                    <button (click)="createGame()" *ngIf="!shouldConnect" class="btn d-flex justify-content-center"
                        [disabled]="form.invalid">{{'FieldInfo.CreateGame'|translate|uppercase}}</button>
                    <div class="btn m-auto d-flex justify-content-center" *ngIf="shouldConnect" routerLink="/login">
                        {{'Home.Login'|translate}}</div>
                </div>
            </div>
        </form>
    </div>
</div>