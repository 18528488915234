<div class="container">
    <!-- <img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg1">
    <img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg2"> -->
    <div class="row">
        <div class="col-12 col-md-8 mx-auto rules">
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.Log'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.LogContent1'|translate}}</p>
                    <p>{{'Help.LogContent2'|translate}}{{'Header.Profile'|translate|uppercase}}.</p>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.Field'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.FieldContent1'|translate}}</p>
                    <p>{{'Help.FieldContent2'|translate}}</p>
                    <p>{{'Help.FieldContent3'|translate}}</p>
                    <!-- <p>{{'Help.FieldContent4'|translate}}</p> -->
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.CreateGame'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.CreateGameContent1'|translate}}</p>
                    <p>{{'Help.CreateGameContent2'|translate}}</p>
                    <p>{{'Help.CreateGameContent3'|translate}}</p>
                    <!-- <p>{{'Help.CreateGameContent4'|translate}}</p> -->
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.JoinGame'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.JoinGameContent1'|translate}}</p>
                    <p>{{'Help.JoinGameContent2'|translate}}</p>
                    <p>{{'Help.JoinGameContent3'|translate}}</p>
                    <p>{{'Help.JoinGameContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.Invite'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.InviteContent1'|translate}}</p>
                    <!-- <p>{{'Help.InviteContent2'|translate}}</p> -->
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>- {{'Help.Pay2Play'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- <p>{{'Help.Pay2PlayContent1'|translate}}</p> -->
                    <p>{{'Help.Pay2PlayContent2'|translate}}</p>
                    <p>{{'Help.Pay2PlayContent3'|translate}}</p>
                    <p>{{'Help.Pay2PlayContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>{{'Help.Problem'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.ProblemContent1'|translate}}</p>
                    <p>{{'Help.ProblemContent2'|translate}}</p>
                    <p>{{'Help.ProblemContent3'|translate}}</p>
                    <p>{{'Help.ProblemContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion> -->
            <!-- <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>{{'Help.BuyCredits'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.BuyCreditsContent1'|translate}}</p>
                    <p>{{'Help.BuyCreditsContent2'|translate}}</p>
                    <p>{{'Help.BuyCreditsContent3'|translate}}</p>
                    <p>{{'Help.BuyCreditsContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>{{'Help.PayToPlay'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.PayToPlayContent1'|translate}}</p>
                    <p>{{'Help.PayToPlayContent2'|translate}}</p>
                    <p>{{'Help.PayToPlayContent3'|translate}}</p>
                    <p>{{'Help.PayToPlayContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
                <mat-expansion-panel hideToggle (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    style="background-color: rgba(0, 0, 0, 0);">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>{{'Help.CGU'|translate|uppercase}}</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{'Help.CGUContent1'|translate}}</p>
                    <p>{{'Help.CGUContent2'|translate}}</p>
                    <p>{{'Help.CGUContent3'|translate}}</p>
                    <p>{{'Help.CGUContent4'|translate}}</p>
                </mat-expansion-panel>
            </mat-accordion> -->
        </div>
        
    </div>
    
</div>