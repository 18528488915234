import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DialogQuestionComponent } from '../dialog-question/dialog-question.component';
import { ChatDoc } from '../models/chat-doc';
import { Field } from '../models/field';
import { Game } from '../models/game';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { BalanceService } from '../services/balance.service';
import { ChatDocContent, ChatDocService } from '../services/chat-doc.service';
import { FieldsService } from '../services/fields.service';
import { GamesService } from '../services/games.service';
import { SwitchComponent } from '../switch/switch.component';
import { InviteComponent } from './invite/invite.component';

@Component({
  selector: 'app-single-game',
  templateUrl: './single-game.component.html',
  styleUrls: ['./single-game.component.scss']
})
export class SingleGameComponent implements OnInit, OnDestroy {
  user: User | undefined;
  gameSelected: Game | undefined;
  fieldSelected: Field | undefined;
  teamSide: number[] | undefined;
  levels: Map<string, number> = new Map();
  lastAdmin: boolean = false;
  newStart: boolean = false;
  newStartTime!: Date;
  now = new Date();
  copied = false;
  finished = false;
  loading = true;
  cantDelete = false;
  chatDoc: ChatDoc | undefined;
  newMsg: string = '';
  modifyPw = false;
  switchTeams = false;
  pwValue: string = '';
  requestCount = 0;

  constructor(private authService: AuthService,
    private translate: TranslateService,
    private gameService: GamesService,
    private balanceService: BalanceService,
    private chatDocService: ChatDocService,
    private router: Router,
    private fieldService: FieldsService, private dialog: MatDialog) { }


  ngOnInit(): void {
    let gameId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    this.auth();
    this.setMyGame(gameId);
    // this.setChat(gameId);
  }

  ngOnDestroy(): void {
    this.gameSelected = undefined;
    this.fieldSelected = undefined;
  }

  auth() {
    if (this.authService.user && this.gameSelected) {
      this.user = this.authService.user;
      // this.setChat(this.gameSelected?._id)
      this.reloadGame(this.gameSelected._id);
    } else {
      setTimeout(() => {
        this.auth();
      }, 1000)
    }
  }

  setMyGame(id: string) {
    this.gameSelected = undefined;
    this.fieldSelected = undefined;

    this.gameService.getGameById(id)
      .then((game: Game) => {
        this.loading = false;
        this.gameSelected = game;
        this.cantDelete = new Date(this.now.toISOString()).getTime() > new Date(this.gameSelected?.start).getTime();
        this.finished = new Date(game.end).getTime() >= this.now.getTime();
        this.fieldSelected = undefined;
        this.getPlayersLevel(this.gameSelected);
        this.teamSide = new Array(this.gameSelected.side);
        this.fieldService.getFieldById(this.gameSelected.fieldId).then((field: Field) => {
          this.fieldSelected = field;
        })
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  }

  // setChat(gameId: string) {
  //   if (this.user && (this.gameSelected?.playersId.includes(this.user.id) || this.user.id == this.gameSelected?.admin)) {
  //     this.reloadGame(gameId);
  //   } else {
  //     setTimeout(() => {
  //       this.reloadGame(gameId);
  //     }, 1000)
  //   }
  // }

  reloadGame(gameId: string) {
    if (this.gameSelected) {
      this.gameService.getGameAndChatById(gameId).then((gamechat: any) => {
        if (gamechat.chat) this.chatDoc = gamechat.chat;
        if (this.gameSelected && !this.compareGames(gamechat.game, this.gameSelected) && !this.switchTeams) {
          this.gameSelected = gamechat.game;
          this.requestCount = 0;
        }
        this.scrollBottom('chat');
        this.requestCount++;
        if (this.requestCount < 60) {
          setTimeout(() => {
            this.reloadGame(gameId);
          }, 5000)
        } else {
          this.router.navigate(['/mygames'])
        }
      })
    }
  }

  compareGames(game1: Game, game2: Game): boolean {
    return (game1.activeChat == game2.activeChat) &&
      this.compareStringArray(game1.players[0], game2.players[0]) &&
      this.compareStringArray(game1.players[1], game2.players[1]) &&
      this.compareStringArray(game1.playersId, game2.playersId) &&
      (game1.active == game2.active) &&
      (game1.isPublic == game2.isPublic) &&
      (game1.start == game2.start) &&
      (game1.modificationDate == game2.modificationDate)
  }

  compareStringArray(arr1: string[], arr2: string[]): boolean {
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] != arr2[i]) return false;
    }
    return true;
  }


  activeChat(game: Game) {
    game.activeChat = !game.activeChat;
    this.gameService.updateGame(game, true, game.status == 'complete').then(() => {
      this.gameSelected = game;
    });
  }

  scrollBottom(elementId: string) {
    setTimeout(() => {
      document.getElementById(elementId)?.scroll({ top: 1500 })
    }, 100)
  }

  sendMsg() {
    if (this.user && this.chatDoc) {
      let content = new ChatDocContent();
      content.date = new Date();
      content.visible = true;
      content.sender = this.user?.pseudo;
      content.msg = this.newMsg;
      this.chatDoc.content.push(content)
      this.chatDocService.updateChatDoc(this.chatDoc, content);
      this.newMsg = '';
      this.scrollBottom('chat');
    }
  }

  resetChat(chat: ChatDoc) {
    chat.content = [];
    this.chatDocService.resetChatDoc(chat,);
  }

  newStartingTime() {
    if (this.newStartTime) {
      let date: Date = this.newStartTime;
      let game = this.gameSelected ? this.gameSelected : null;
      if (game) {
        game.date = new Date(date);
        game.start = new Date(date);
        game.end = new Date(game.start.getTime() + 1000 * 60 * 60);
      };
      if (game) this.gameService.updateGame(game)
      this.newStart = false;
    }
  }

  getPlayersLevel(game: Game) {
    let playersId: string[] = game.playersId;
    playersId.forEach((playerId) => {
      this.authService.getUserById(playerId)
        .then((user: any) => {
          this.levels.set(user.pseudo, game.sport == 'foot' ? user.footballLevel : user.basketballLevel);
        })
        .catch((err) => {
          console.log(err);
        })
    })
  }

  editGotBall(pseudo: string) {
    if (pseudo == this.user?.pseudo) {
      const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('FieldInfo.BringBall') });

      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (res == true) {
          this.gameSelected?.gotBall.push(pseudo);
        } else {
          if (this.gameSelected) this.gameSelected.gotBall = this.gameSelected?.gotBall.filter((element) => element != pseudo);
        }
        if (this.gameSelected) this.gameService.updateGame(this.gameSelected, true);
      })
    }
  }

  editPublic() {
    if (this.user && this.gameSelected ? this.gameSelected.admin === this.user.id : null) {
      const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('MyGames.GoPublic') });

      dialogRef.afterClosed().subscribe((res: boolean) => {
        if (this.gameSelected) {
          if (res == true && this.gameSelected) {
            this.gameSelected.isPublic = true;
          } else {
            if (this.gameSelected) this.gameSelected.isPublic = false;
          }
          this.gameService.updateGame(this.gameSelected, true);
        }
      })
    }
  }

  editStart() {
    if (this.user && this.gameSelected ? this.gameSelected.admin === this.user.id : null) {
      this.newStart = true;
    }
  }

  editGamePw(game: Game) {
    game.gamePassword = this.pwValue;
    this.gameService.updateGame(game, true);
    this.modifyPw = false;
  }

  openInvite() {
    try {
      navigator.clipboard.writeText(window.location.href);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 5000)
    } catch (error) {
      this.dialog.open(InviteComponent);
    }

  }

  openSwitch() {
    this.switchTeams = true;
    this.dialog.open(SwitchComponent, { data: this.gameSelected }).afterClosed().subscribe(() => {
      this.switchTeams = false;
    });
  }

  joinGame(game: Game, team: number, pseudo: string, id: string) {

    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('FieldInfo.BringBall') });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res == true) game.gotBall.push(pseudo)
      game.players[team].push(pseudo);
      game.playersId.push(id);
      this.gameService.updateGame(game)
        .then(() => {
          this.reloadGame(game._id);
        })
        .catch((error) => {
          console.log(error);
        });
    })

  }

  cancel(game: Game, pseudo: string) {
    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('MyGames.ConfirmOut') });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res == true) {
        this.authService.getUserByPseudo(pseudo).then((user: any) => {
          game.playersId = game.playersId.filter((id) => id != user._id);
          game.gotBall = game.gotBall.filter((pseudoo) => pseudoo != pseudo);
          game.players[0] = game.players[0].filter((ps) => ps != pseudo);
          game.players[1] = game.players[1].filter((ps) => ps != pseudo);
          game.modificationDate = new Date();
          this.gameService.updateGame(game)
        }).catch(() => { });
      }

    })
  }

  goTo(field: Field) {
    this.router.navigate(['/map/' + field.lat + '&' + field.lng]);
  }

  array(length: number | undefined) {
    return length != -1 ? new Array(length) : [];
  }

  closeAndConfirm(game: Game) {
    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('MyGames.ConfirmClose') });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res == true) {
        this.gameService.updateGame(game, false, true).then(() => {
          window.location.reload();
        }).catch(() => {
        })
      }
    })
  }

  deleteGame(game: Game) {
    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('MyGames.ConfirmDeleteGame') });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res == true) {
        if (new Date(this.now.toISOString()).getTime() < new Date(game.start).getTime()) {
          // Check if pay2play mode
          if (game.price > 0 && this.user) {
            this.balanceService.updateBalanceByPseudo(this.user?.pseudo, 1).then(() => {
              this.gameService.deleteGame(game._id).then(() => {
                this.router.navigate(['/*'])
              })
            })
          } else {
            this.gameService.deleteGame(game._id).then(() => {
              this.router.navigate(['/*'])
            })
          }
        }

      }
    })
  }
}
