import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {MatIconModule} from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { MatDialogModule } from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { CalendarComponent } from './calendar/calendar.component';
import { YamatchComponent } from './yamatch/yamatch.component';
import { MygamesComponent } from './mygames/mygames.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FieldInfoComponent } from './field-info/field-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { RecoverComponent } from './login/recover/recover.component';
import { ConfirmComponent } from './signup/confirm/confirm.component';
import { NewFieldComponent } from './map/new-field/new-field.component';
import { SingleGameComponent } from './single-game/single-game.component';
import { InviteComponent } from './single-game/invite/invite.component';
import { FieldListComponent } from './map/field-list/field-list.component';
import { ProfileComponent } from './profile/profile.component';
import { HowtoplayComponent } from './howtoplay/howtoplay.component';
import { LogsComponent } from './logs/logs.component';
import { CardComponent } from './profile/card/card.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { PaypalComponent } from './paypal/paypal.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { MatRadioModule } from '@angular/material/radio';
import { FieldsComponent } from './fields/fields.component';
import { SwitchComponent } from './switch/switch.component';
import { SocialComponent } from './social/social.component';
import { LanguageComponent } from './header/language/language.component';
import { CguComponent } from './signup/cgu/cgu.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperComponent } from './cropper/cropper.component';
import { DialogQuestionComponent } from './dialog-question/dialog-question.component';
import { FieldCardComponent } from './map/field-list/field-card/field-card.component';
import { ImageWithLoadingComponent } from './map/field-list/field-card/image-with-loading/image-with-loading.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MapComponent,
    CalendarComponent,
    YamatchComponent,
    MygamesComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    RecoverComponent,
    SignupComponent,
    ConfirmComponent,
    FieldInfoComponent,
    NewFieldComponent,
    SingleGameComponent,
    InviteComponent,
    FieldListComponent,
    ProfileComponent,
    HowtoplayComponent,
    LogsComponent,
    CardComponent,
    PaypalComponent,
    PurchaseComponent,
    FieldsComponent,
    SwitchComponent,
    SocialComponent,
    LanguageComponent,
    CguComponent,
    CropperComponent,
    DialogQuestionComponent,
    FieldCardComponent,
    ImageWithLoadingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatDialogModule,
    
    MatIconModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    MatExpansionModule,
    MatRadioModule,
    MatMenuModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NoopAnimationsModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
