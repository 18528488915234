import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Game } from "../models/game";
import { ChatDocService } from "./chat-doc.service";
import { EmailService } from "./email.service";

@Injectable({
    providedIn: 'root'
})
export class GamesService {
    games: Game[] = [];
    loadedGames: Game[] = [];

    constructor(private http: HttpClient, private emailService: EmailService, private chatService: ChatDocService) { }

    getGames(): Promise<Game[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/games/')
                .subscribe((games: any) => {
                    this.games = [];
                    this.games = games;
                    for (let i = 0; i < games.length; i++) {
                        this.games.push({
                            _id: games[i].id,
                            sport: games[i].sport,
                            side: games[i].side,
                            fieldId: games[i].fieldId,
                            level: games[i].level,
                            date: new Date(Date.parse(games[i].date)),
                            start: new Date(Date.parse(games[i].start)),
                            end: new Date(Date.parse(games[i].end)),
                            activeChat: games[i].activeChat,
                            gamePassword: games[i].gamePassword,
                            players: games[i].players,
                            playersId: games[i].playersId,
                            gotBall: games[i].gotBall,
                            isPublic: games[i].isPublic,
                            admin: games[i].admin,
                            price: games[i].price,
                            currency: games[i].currency,
                            status: games[i].status,
                            creationDate: new Date(Date.parse(games[i].creationDate)),
                            modificationDate: new Date(Date.parse(games[i].modificationDate)),
                            active: games[i].active,
                        })
                    }
                    resolve(this.games)
                },
                    (error) => {
                        console.log(error);
                        reject([])
                    });
        });
    }

    getGameById(id: string): Promise<Game> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/games/game/' + id)
                .subscribe((game: any) => {
                    resolve(game)
                },
                    (error) => {
                        console.log(error);
                        reject(undefined)
                    });
        });
    }

    getGameAndChatById(gameId: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/games/gamechat/' + gameId)
                .subscribe((gamechat: any) => {
                    resolve(gamechat)
                },
                    (error) => {
                        console.log(error);
                        reject(undefined)
                    });
        });
    }

    getMyGames(userId: string): Promise<Game[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/games/mygames/' + userId)
                .subscribe((games: any) => {
                    this.games = [];
                    for (let i = 0; i < games.length; i++) {
                        this.games.push({
                            _id: games[i]._id,
                            sport: games[i].sport,
                            side: games[i].side,
                            fieldId: games[i].fieldId,
                            level: games[i].level,
                            date: new Date(Date.parse(games[i].date)),
                            start: new Date(Date.parse(games[i].start)),
                            end: new Date(Date.parse(games[i].end)),
                            activeChat: games[i].activeChat,
                            gamePassword: games[i].gamePassword,
                            players: games[i].players,
                            playersId: games[i].playersId,
                            gotBall: games[i].gotBall,
                            isPublic: games[i].isPublic,
                            admin: games[i].admin,
                            price: games[i].price,
                            currency: games[i].currency,
                            status: games[i].status,
                            creationDate: new Date(Date.parse(games[i].creationDate)),
                            modificationDate: new Date(Date.parse(games[i].modificationDate)),
                            active: games[i].active,
                        })
                    }
                    resolve(this.games)
                },
                    (error) => {
                        console.log(error);
                        reject([])
                    });
        });
    }

    getFutureGames(): Promise<Game[]> {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/games/next')
                .subscribe((games: any) => {
                    this.games = [];
                    this.games = games;
                    resolve(this.games);
                },
                    (error) => {
                        console.log(error);
                        reject([]);
                    });
        });
    }

    getGamesByFieldId(id: string | undefined): Promise<Game[]> {
        return new Promise((resolve, reject) => {
            if (id) {
                this.http.get(environment.protocol + '://' + environment.path + '/api/games/' + id)
                    .subscribe((games: any) => {
                        this.loadedGames = games;
                        resolve(this.loadedGames);
                    },
                        (error) => {
                            console.log(error);
                            reject([]);
                        });
            } else {
                reject([]);
            }
        });
    }

    createGame(game: Game): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/games/', { game: game })
                .subscribe(() => {
                    resolve(true)
                },
                    (error) => {
                        console.log('error', error);
                        reject(false)
                    });
        });
    }

    cancel(game: Game, pseudo: string, userId: string) {
        game.playersId = game.playersId.filter((el) => el != userId);
        game.gotBall = game.gotBall.filter((el) => el != pseudo);
        game.players[0] = game.players[0].filter((el) => el != pseudo);
        game.players[1] = game.players[1].filter((el) => el != pseudo);
        this.updateGame(game);
    }

    updateGame(game: Game, gotBallChange?: boolean, confirmed?: boolean): Promise<boolean> {
        if (this.isComplete(game)|| confirmed) {
            game.status = 'complete';
        } else {
            game.status = 'pending';
        }
        return new Promise((resolve, reject) => {
            this.http.put(environment.protocol + '://' + environment.path + '/api/games/', { game: game })
                .subscribe(() => {
                    if (game.status == 'complete' && !gotBallChange) {
                        this.emailService.sendGameComplete(game._id);
                    }
                    resolve(true);
                },
                    (error) => {
                        console.log('error', error);
                        reject(false);
                    });
        });
    }

    isComplete(game: Game): boolean {
        return game.playersId.length == (game.side * 2);
    }

    deleteGame(_id: string) {
        return new Promise((resolve, reject) => {
            this.http.delete(environment.protocol + '://' + environment.path + '/api/games/'+_id)
                .subscribe(() => {
                    resolve(true);
                },
                    (error) => {
                        reject(false);
                    });
        });
    }
}