import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Field } from 'src/app/models/field';
import { FieldsResponse, FieldsService } from 'src/app/services/fields.service';

@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.scss']
})
export class FieldListComponent implements OnInit {
  fields: Field[] = [];
  displayFields: Field[] = [];
  loading = true;
  maxCount = 0;
  searchText: Subject<string> = new Subject();

  constructor(private fieldService: FieldsService, private dialog: MatDialogRef<FieldListComponent>) {
  }

  ngOnInit(): void {
    this.setFields();
    this.initSearch();
  }

  setFields() {
    this.fieldService.getFields().then((fieldsResponse: FieldsResponse) => {
      this.fields = fieldsResponse.fields;
      this.maxCount = fieldsResponse.maxCount;
      this.displayFields = this.fields;
      this.loading = false;
    }).catch(() => this.loading = false);
  }

  initSearch() {
    this.searchText.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((search) => this.fieldService.getFieldsBySearch(search))
    ).subscribe((res: Field[]) => {
      this.displayFields = res;
    })
  }

  goToFieldId(id: string) {
    this.dialog.close(id)
  }

  search(event: any) {
    if (event.target.value) {
      this.searchText.next(event.target.value)
    } else {
      this.setFields();
    }
  }

}
