<div class="container d-flex flex-column justify-content-center align-items-center">
    <h2 class="row text-center white">{{data}}</h2>

    <div class="w-100 row d-flex flex-row justify-content-around">
        <button class="col-4 btn" (click)="dialogRef.close()">
            <mat-icon>close</mat-icon>
        </button>
        <button class="col-4 btn" (click)="validate()">
            <mat-icon>check</mat-icon>
        </button>
    </div>
    
</div>