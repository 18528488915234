<div class="header container-fluid d-flex justify-content-between secondary">
    <div class="row w-100 align-items-center">
        <div class="col-8 col-md-3 d-flex align-items-center">
            <h1 class="m-0"><a class="title" routerLink="#">YaaMatch</a></h1><span class="p-2" (click)="translate()"
                style="cursor: pointer;">{{'Header.Lang'|translate|uppercase}}</span>
        </div>
        <div class="col-4 col-md-9 text-end p-0">
            <div id="burger" mat-button [matMenuTriggerFor]="menu">
                <svg width="32" height="32" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 5H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3 12H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M3 19H21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </div>
            <mat-menu #menu="matMenu">
                <a class="" routerLink="/map" routerLinkActive="active-link"
                    mat-menu-item>{{'Header.Map'|translate|uppercase}}</a>
                <a (click)="translate()" mat-menu-item>{{'Home.Language'|translate|uppercase}}</a>
                <a class="" routerLink="/howtoplay" routerLinkActive="active-link"
                    mat-menu-item>{{'Header.HowToPlay'|translate|uppercase}}</a>
                <a class="" routerLink="/mygames" routerLinkActive="active-link" mat-menu-item
                    *ngIf="user">{{'Header.MyGames'|translate|uppercase}}</a>
                <a class="" routerLink="/profile/{{user?.pseudo}}" routerLinkActive="active-link" mat-menu-item
                    *ngIf="user">{{'Header.Profile'|translate|uppercase}}</a>
                <a class="" mat-menu-item (click)="loginPage()" *ngIf="!user">{{'Home.Login'|translate|uppercase}}</a>
                <a class="" mat-menu-item (click)="logout()" *ngIf="user">{{'Home.Logout'|translate|uppercase}}</a>
            </mat-menu>
            <div class="navbar row text-center white">
                <div class="nav-item" routerLink="map" routerLinkActive="active-link">
                    <img src="../../assets/icons/map.svg" alt="map">
                    <span>{{'Header.Map'|translate|uppercase}}</span>
                </div>
                <div class="nav-item" routerLink="mygames" routerLinkActive="active-link" *ngIf="user">
                    <img src="../../assets/icons/mygames.svg" alt="mygames">
                    <span>{{'Header.MyGames'|translate|uppercase}}</span>
                </div>
                <div class="nav-item" routerLink="profile/{{user?.pseudo}}" routerLinkActive="active-link" *ngIf="user">
                    <img src="../../assets/icons/profile.svg" alt="profile">
                    <span>{{'Header.Profile'|translate|uppercase}}</span>
                </div>
                <div class="nav-item" routerLink="howtoplay" routerLinkActive="active-link">
                    <img src="../../assets/icons/help.svg" alt="help">
                    <span>{{'Header.HowToPlay'|translate|uppercase}}</span>
                </div>
                <div class="nav-item" *ngIf="!user" (click)="loginPage()">
                    <img src="../../assets/icons/login.svg" alt="login">
                    <span>{{'Home.Login'|translate|uppercase}}</span>
                </div>
                <div class="nav-item" *ngIf="user" (click)="logout()">
                    <img src="../../assets/icons/logout.svg" alt="logout">
                    <span>{{'Home.Logout'|translate|uppercase}}</span>
                </div>
            </div>
        </div>

    </div>
</div>