import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarComponent } from './calendar/calendar.component';
import { HomeComponent } from './home/home.component';
import { LogsComponent } from './logs/logs.component';
import { RecoverComponent } from './login/recover/recover.component';
import { MapComponent } from './map/map.component';
import { MygamesComponent } from './mygames/mygames.component';
import { ProfileComponent } from './profile/profile.component';
import { ConfirmComponent } from './signup/confirm/confirm.component';
import { SingleGameComponent } from './single-game/single-game.component';
import { YamatchComponent } from './yamatch/yamatch.component';
import { HowtoplayComponent } from './howtoplay/howtoplay.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { FieldsComponent } from './fields/fields.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {path:'', component: HomeComponent},
  {path:'map', component: MapComponent},
  {path:'map/:pos', component: MapComponent},
  {path:'calendar', component: CalendarComponent},
  {path:'yamatch', component: YamatchComponent},
  {path:'mygames', component: MygamesComponent},
  {path:'login', component: LogsComponent},
  {path:'howtoplay', component: HowtoplayComponent},
  {path:'fieldsManagement', component: FieldsComponent},
  {path:'game/:id', component: SingleGameComponent},
  {path:'profile/:id', component: ProfileComponent},
  {path:'addCoins/:id', component: PurchaseComponent, canActivate: [AuthGuard]},
  {path: "recover/:id", component: RecoverComponent},
  {path: "confirm/:id", component: ConfirmComponent},
  {path:'*', redirectTo: ''},
  {path:'**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
