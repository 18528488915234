import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private http: HttpClient) { }

    saveEmail(email: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email', { email: email }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    getEmails() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/email').subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    isRecoveryLinkValid(id: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/email/recover' + id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    sendConfirmLink(email: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/sendconfirmation', { mailTo: email }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    sendGameComplete(gameId: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/email/complete/' + gameId).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    
    sendContactPlayer(pseudo: string, subject: string, message: string, from: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/contact/' + pseudo, {subjectContent: subject, messageContent: message, sender: from}).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
    
    sendReportPlayer(pseudo: string, subject: string, message: string, from: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/report/' + pseudo, {subjectContent: subject, messageContent: message, sender: from}).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}