import { Component, OnInit } from '@angular/core';
import { Game } from '../models/game';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { GamesService } from '../services/games.service';
import { FieldsService } from '../services/fields.service';
import { Field } from '../models/field';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-mygames',
  templateUrl: './mygames.component.html',
  styleUrls: ['./mygames.component.scss']
})
export class MygamesComponent implements OnInit {
  user: User | undefined;
  games: Game[] | undefined;
  fields: Field[] | undefined;
  now = new Date();
  linkForm: FormGroup;
  gameId: string = '';
  validGame: Game | undefined;
  loading = true;

  constructor(private authService: AuthService, private gameService: GamesService, private fieldService: FieldsService, private router: Router) {
    this.linkForm = new FormGroup({
      link: new FormControl('', [])
    })
  }

  ngOnInit(): void {
    this.auth();
    this.linkForm.valueChanges.subscribe((change: any) => {
      this.gameId = change.link.substring(change.link.lastIndexOf('/')+1);
      this.gameService.getGameById(this.gameId).then((game) => {
        if (game.start) this.validGame = game;
      })
    })
  }

  auth() {
    if (this.authService.user) {
      this.user = this.authService.user;
      this.setMyGames(this.user.id)
    } else {
      setTimeout(() => {
        this.auth();
      }, 300)
    }
  }

  setMyGames(id: string) {
    this.fields = undefined;
    this.games = undefined;

    this.gameService.getMyGames(id)
      .then((games: Game[]) => {
        this.games = games;
        this.fields = [];
        for (let i = 0; i < this.games.length; i++) {
          this.fieldService.getFieldById(this.games[i].fieldId).then((field: Field) => {
            if (this.fields) this.fields[i] = field;
          })
        }
        this.loading = false
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  }

  openGame(game: Game) {
    this.router.navigate(['game/'+game._id])
  }

  cancel(game: Game, pseudo: string, userId: string) {
    this.gameService.cancel(game, pseudo, userId)
  }

}

