<div class="container mt-5" *ngIf="!selectedField">
    <div class="d-flex flex-column">
        <div>
            <label for="search">Ville/Nom/Adresse</label>
            <input type="text" [(ngModel)]="newSearch">
            <!-- <input type="text" formControlName="search" id="search"> -->
            <button class="btn" (click)="search()">Rechercher</button>
        </div>
        <form [formGroup]="searchForm">
            
            <!-- <div>
            <label for="name">Par Nom</label>
            <input type="text" formControlName="name" id="name">
            <button class="btn" (click)="searchByName()">Rechercher</button>
        </div>
        <div><label for="address">Par Addresse</label>
            <input type="text" formControlName="address" id="address">
            <button class="btn" (click)="searchByAddress()">Rechercher</button>
        </div> -->
            <div>
                <label for="display" style="width: auto;"><button class="btn" (click)="display()"
                        [disabled]="!searchForm.get('display')?.value">Afficher</button> les
                    <select formControlName="display" id="display">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    derniers terrains ajoutés</label>
            </div>
        </form>
    </div>
    <div class="row d-flex" *ngIf="fields">
        <div *ngIf="fields.length==0">
            <p>Pas de résultat</p>
        </div>
        <div *ngFor="let field of fields" class="fieldCard" (click)="selectField(field._id)">
            <img class="mx-auto" [src]="field.photoUrl" alt="" width="120" height="100">
            <p class="text-truncate">{{field.name}}</p>
            <p class="text-truncate">{{field.city}}</p>
            <p class="text-truncate">{{field.address}}</p>
            <p class="text-truncate">{{field.sport}} - max: {{field.side}}v{{field.side}}</p>
            <p class="text-truncate">créé le {{field.creationDate|date:'dd/MM/yyyy'}}</p>
            <p class="text-truncate" *ngIf="field?.creationUser">par {{field.creationUser}}</p>

        </div>
    </div>
</div>
<div class="container mt-5" *ngIf="selectedField">
    <button class="btn m-3" (click)="back()">RETOUR</button>
    <button class="btn m-3" (click)="edit()">ENREGISTRER LES MODIFICATIONS</button>
    <form [formGroup]="editForm">
        <p class="text-truncate">Nom: {{selectedField.name}} <span><input type="text" formControlName="name"><button
                    class="btn" *ngIf="editForm.get('name')?.value"
                    (click)="selectedField.name=editForm.get('name')?.value">VALIDER</button></span></p>
        <p class="text-truncate">Ville: {{selectedField.city}} <span><input type="text" formControlName="city"><button
                    class="btn" *ngIf="editForm.get('city')?.value"
                    (click)="selectedField.city=editForm.get('city')?.value">VALIDER</button></span></p>
        <p class="text-truncate">Adresse: {{selectedField.address}} <span><input type="text"
                    formControlName="address"><button class="btn" *ngIf="editForm.get('address')?.value"
                    (click)="selectedField.address=editForm.get('address')?.value">VALIDER</button></span></p>
        <p class="text-truncate"><img [src]="selectedField.photoUrl" alt="photo" width="250px"></p>
        <div class="row">
            <div class="col-12">
                <button class="btn" type="button"
                    (click)="filePicker.click()">{{'FieldInfo.AddImage'|translate|uppercase}}</button>
                <input hidden="true" type="file" #filePicker (change)="onImagePick($event)" id="photoUrl"
                    name="photoUrl">
            </div>
        </div>
        <div class="row m-3" style="width: 250px;">
            <div class="image-preview" *ngIf="imagePreview">
                <div style="background-image: url('{{imagePreview}}');
                                                                    background-size: cover; padding-top: 56.25%;">
                </div>
            </div>
        </div>

        <p class="text-truncate">Latitude: {{selectedField.lat}} <span><input type="number"
                    formControlName="lat"><button class="btn" *ngIf="editForm.get('lat')?.value"
                    (click)="selectedField.lat=editForm.get('lat')?.value">VALIDER</button></span></p>
        <p class="text-truncate">Longitude: {{selectedField.lng}} <span><input type="number"
                    formControlName="lng"><button class="btn" *ngIf="editForm.get('lng')?.value"
                    (click)="selectedField.lng=editForm.get('lng')?.value">VALIDER</button></span></p>
        <p class="text-truncate">Sport(s): {{selectedField.sport}} <span>
                <input type="checkbox" id="foot" formControlName="foot" value="foot" hidden />
                <label class="label-edit text-center" for="foot"
                    [ngClass]="{'checked': editForm.get('foot')?.value==true}">{{'Home.Foot'|translate|uppercase}}</label>
                <input type="checkbox" id="basket" formControlName="basket" value="basket" hidden />
                <label class="label-edit text-center" for="basket"
                    [ngClass]="{'checked': editForm.get('basket')?.value==true}">{{'Home.Basket'|translate|uppercase}}</label>


                <button class="btn" (click)="setSport()">VALIDER</button></span></p>
        <p class="text-truncate">Max: {{selectedField.side}}v{{selectedField.side}} <span><input type="number"
                    placeholder="1-2-3-5-7-11" formControlName="max"><button class="btn"
                    *ngIf="editForm.get('max')?.value"
                    (click)="selectedField.side=editForm.get('max')?.value">VALIDER</button></span></p>
        <p class="text-truncate">Statut: {{selectedField.status}} <span><input type="text"
                    formControlName="status"><button class="btn" *ngIf="editForm.get('status')?.value"
                    (click)="selectedField.status=editForm.get('status')?.value">VALIDER</button></span></p>
        <p>Ajouté par : {{selectedField.creationUser}}</p>
        <p class="text-truncate">Créé le: {{selectedField.creationDate|date:'dd/MM/yyyy HH:mm'}}</p>
        <p class="text-truncate">Modifié le: {{selectedField.modificationDate|date:'dd/MM/yyyy HH:mm'}}</p>
        <p class="text-truncate">_id: {{selectedField._id}}</p>
        <button class="btn m-3" (click)="delete()"><span>SUPPRIMER</span></button>

    </form>
</div>