<div style="height: 80vh;">
    <div *ngIf="data=='cgu'">
        <h3>{{'CGU.title'|translate|uppercase}}</h3>
        <p>{{'CGU.p1'|translate}}</p>
        <p>{{'CGU.p2'|translate}}</p>
        <p>{{'CGU.p3'|translate}}</p>
        <p>{{'CGU.p4'|translate}}</p>
        <p>{{'CGU.p5'|translate}}</p>
        <p>{{'CGU.p6'|translate}}</p>
        <p>{{'CGU.p6.1'|translate}}</p>
        <p>{{'CGU.p7'|translate}}</p>
        <p>{{'CGU.p8'|translate}}</p>
        <p>{{'CGU.p9'|translate}}</p>
        <p>{{'CGU.p10'|translate}}</p>
        <p>{{'CGU.p11'|translate}}</p>
    </div>
    
    
    <div *ngIf="data=='cgv'">
        <h3>{{'CGV.title'|translate|uppercase}}</h3>
        <p>{{'CGV.p1'|translate}}</p>
        <p>{{'CGV.p2'|translate}}</p>
        <p>{{'CGV.p3'|translate}}</p>
        <p>{{'CGV.p4'|translate}}</p>
        <p>{{'CGV.p5'|translate}}</p>
    </div>
    
    
    <div *ngIf="data=='cookies'">
        <h3>{{'Cookies.title'|translate|uppercase}}</h3>
        <p>{{'Cookies.p1'|translate}}</p>
        <p>{{'Cookies.p2'|translate}}</p>
        <p>{{'Cookies.p3'|translate}}</p>
        <p>{{'Cookies.p4'|translate}}</p>
        <p>{{'Cookies.p5'|translate}}</p>
    </div>
</div>
