import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Profile } from '../models/profile';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isAuth$ = new BehaviorSubject<boolean>(false);
  user: User | undefined;
  lastUrl: string = '';
  private $g_token: string = '';

  constructor(private http: HttpClient, private titleService: Title) {
    this.$g_token = window.sessionStorage.getItem('g_token') || '';
  }

  hasToken() {
    return this.$g_token != '';
  }

  loginWithGoogle() {    
    return new Promise((resolve, reject) => {
      this.http.post(environment.protocol + '://' + environment.path + '/api/auth/logingoogle', { token: this.$g_token })
        .subscribe((user: any) => {
          this.setUser(user);
              this.titleService.setTitle(this.titleService.getTitle() + ' - ' + user?.pseudo);
              this.isAuth$.next(true);
              if (this.user) {
                window.sessionStorage.clear();
                window.sessionStorage.setItem('authToken', this.user?.token);
                window.localStorage.setItem('authToken', this.user?.token);
              }
              resolve(this.user);
          resolve(user);
        }, (err) => {
          reject(err);
        })
    })
  }

  createNewUser(pseudo: string, email: string, password: string, city: string, footballLevel: number, basketballLevel: number) {
    return new Promise((resolve, reject) => {
      this.http.post(
        environment.protocol + '://' + environment.path + '/api/auth/signup',
        { pseudo: pseudo, email: email.toLowerCase(), password: password, city: city, footballLevel: footballLevel, basketballLevel: basketballLevel })
        .subscribe(
          (data: any) => {
            this.createNewProfile(data.pseudo);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.http.post(
        environment.protocol + '://' + environment.path + '/api/auth/login',
        { email: email, password: password })
        .subscribe(
          (authData: any) => {
            if (!authData.error) {
              this.setUser(authData);
              this.titleService.setTitle(this.titleService.getTitle() + ' - ' + authData?.pseudo);
              this.isAuth$.next(true);
              if (this.user) {
                window.sessionStorage.setItem('authToken', this.user?.token);
                window.localStorage.setItem('authToken', this.user?.token);
              }
              resolve(this.user);
            } else {
              resolve(authData.error);
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  setUser(authData: any) {
    this.user = new User();
    this.user.id = authData.userId ? authData.userId : authData._id;
    this.user.pseudo = authData.pseudo;
    this.user.email = authData.email;
    this.user.token = authData.token;
    this.user.city = authData.city;
    this.user.profile = authData.profile;
    this.user.footballLevel = authData.footballLevel;
    this.user.basketballLevel = authData.basketballLevel;
    this.user.modificationDate = authData.modificationDate;
    this.user.creationDate = authData.creationDate;
    this.user.active = authData.active;
  }

  getCurrentUser(): Promise<User | undefined> {
    return new Promise((resolve, reject) => {
      if (window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken')) {
        let token = window.sessionStorage.getItem('authToken') ? window.sessionStorage.getItem('authToken') : window.localStorage.getItem('authToken');
        this.http.get(
          environment.protocol + '://' + environment.path + '/api/auth/' + token)
          .subscribe(
            (user: any) => {
              this.setUser(user);
              this.isAuth$.next(true);
              resolve(this.user);
            },
            (error) => {
              reject(undefined);
            }
          );
      } else {
        reject(undefined)
      }
    });
  }

  sendMailTo(email: string) {
    let body = {
      mailTo: email
    };
    return new Promise((resolve, reject) => {
      this.http.post(environment.protocol + '://' + environment.path + '/api/email/send', body).subscribe(
        (mailResponse: any) => {
          resolve(mailResponse);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logout() {
    this.isAuth$.next(false);
    this.user = undefined;
    window.localStorage.clear();
    window.sessionStorage.clear();
    setTimeout(() => {
      window.location.reload();
    })
   
  }

  isMailPresent(mail: string) {
    // const requestOptions = {
    //   headers: new HttpHeaders({
    //     'mail': mail
    //   }),
    // };

    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/email/ispresent/' + mail).subscribe(
        (bool: any) => {
          resolve(bool);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  changePassword(email: string, newPw: string) {
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/recover',
        { email: email, password: newPw })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  confirmAccount(email: string) {
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/confirm',
        { email: email })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  getUserById(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/auth/id/' + id)
        .subscribe(
          (user) => {
            resolve(user);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  getUserByPseudo(pseudo: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/auth/pseudo/' + pseudo)
        .subscribe(
          (user) => {
            resolve(user);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  getProfileById(id: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/profile/' + id)
        .subscribe(
          (profile) => {
            resolve(profile);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  getProfileByPseudo(pseudo: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/profile/pseudo/' + pseudo)
        .subscribe(
          (profile) => {
            resolve(profile);
          },
          (error) => {
            console.log(error);

            reject(error);
          }
        );
    });
  }

  createNewProfile(pseudo: any) {
    return new Promise((resolve, reject) => {
      this.http.post(
        environment.protocol + '://' + environment.path + '/api/profile', { pseudo: pseudo })
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateBalance(newBalance: number, profileId: string) {
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/profile/balance/' + profileId, { balance: newBalance })
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateCity(userId: string, city: string) {
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/city/' + userId, { city: city })
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateProfile(profile: Profile | undefined, age: number, favoritePos: string, description?: string) {
    let newValues = {
      age: age ? age : null,
      favoritePos: favoritePos ? favoritePos : null,
      description: description ? description : null,
      registeredGames: profile?.registeredGames,
      playedGames: profile?.playedGames,
    }
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/profile/' + profile?.id, newValues)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  checkBalance(user: User): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.http.get(
        environment.protocol + '://' + environment.path + '/api/profile/pseudo/' + user.pseudo)
        .subscribe(
          (data: any) => {
            if (data.balance > 0) {
              resolve(data);
            } else {
              reject(data)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getUserLvl(pseudo: string) {
    return new Promise((resolve, reject) => {
      this.http.get(
        environment.protocol + '://' + environment.path + '/api/auth/userLvl/' + pseudo)
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateUserLvl(id: string, footballLevel: number, basketballLevel: number) {
    let newValues = {
      footballLevel: footballLevel ? footballLevel : null,
      basketballLevel: basketballLevel ? basketballLevel : null,
    }
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/updateLvl/' + id, newValues)
        .subscribe(
          (data: any) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateProfilePhoto(profile: Profile, image: File | string | Blob) {


    return new Promise((resolve, reject) => {
      let formData = new FormData();


      if (typeof image == 'string') {
        fetch(image).then((res) => {
          res.blob().then((blob) => {
            image = blob;
            formData.append('image', image, profile.userPseudo);
            this.http.put(environment.protocol + '://' + environment.path + '/api/profile/photo/update/' + profile.userPseudo, formData)
              .subscribe(() => {
                resolve(true)
              },
                (error) => {
                  console.log('error', error);
                  reject(false)
                });
          })
        })
      } else {
        formData.append('image', image, profile.userPseudo);
        this.http.put(environment.protocol + '://' + environment.path + '/api/profile/photo/update/' + profile.userPseudo, formData)
          .subscribe(() => {
            resolve(true)
          },
            (error) => {
              console.log('error', error);
              reject(false)
            });
      }
    });
  }

  getCreditPrice() {
    return new Promise((resolve, reject) => {
      this.http.get(
        environment.protocol + '://' + environment.path + '/api/credit/')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getAdmins() {
    return new Promise((resolve, reject) => {
      this.http.get(
        environment.protocol + '://' + environment.path + '/api/admin/')
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

}
