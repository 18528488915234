<div class="container mt-5" *ngIf="profile">
    <div *ngIf="approved===false">
        <div>{{'Profile.TransactionError'|translate}}
        </div>
        <div class="btn" (click)="reinitialize()">{{'Profile.TryAgain'|translate|uppercase}}</div>
    </div>
    <div *ngIf="approved===true">
        {{'Profile.Congrats'|translate}}
    </div>
    <div *ngIf="approved===undefined" class="credit">
        <div class="row text-center">
            <h2 class="white">{{'Header.Profile'|translate|uppercase}}</h2>
            <div>{{profile.userPseudo}}</div>
        </div>
        <div class="row text-center mt-5">
            <h3 class="white">{{'Profile.CurrentBalance'|translate|uppercase}}</h3>
            <div class="credit">{{profile.balance}} {{'Profile.Credit'|translate|lowercase}}</div>
        </div>
        <div class="row text-center mt-5">
            <h3 class="white">{{'Profile.CreditValue'|translate|uppercase}}</h3>
            <div class="d-flex justify-content-center">
                <button class="btn btn-dark mx-3 credit" *ngFor="let crcy of currencies" (click)="addCurr(crcy)"
                    [disabled]="currency==crcy">{{crcy|slice:0:crcy.indexOf('USD')|number:'0.2'}} $</button>
            </div>
            <p>1 {{'Profile.Credit'|translate|lowercase}} = 1 {{'Profile.PayGameCreation'|translate|lowercase}} </p>
        </div>
        <div class="row text-center mt-5">
            <h3 class="white">{{'Profile.Quantity'|translate|uppercase}}</h3>
            <div class="d-flex justify-content-center">
                <button class="btn btn-dark mx-3" *ngFor="let qtt of quantities" (click)="addQtt(qtt)"
                    style="font-weight: 900;" [disabled]="quantity==qtt">{{qtt}}</button>
            </div>
        </div>
        <div class="row text-center my-5" *ngIf="quantity && currency">
            <button class="btn mx-auto" style="max-width: 400px;" (click)="priceConfirmed=true; scrollDown()"
                [disabled]="priceConfirmed">{{'Profile.Confirm'|translate|uppercase}} {{price|number:'0.2'}}$</button>
            <div *ngIf="priceConfirmed" class="mt-3">
                <app-paypal [price]="price" [currency]="curr" [profileId]="profile.id" [quantity]="quantity"
                    (approved)="validTransaction($event)"></app-paypal>
            </div>
        </div>
    </div>
</div>