<div class="container pt-5" *ngIf="loading">
    <div class="d-flex justify-content-around">
        <p *ngIf="user">{{'Logs.Loading'|translate|uppercase}}</p>
        <p *ngIf="!user">{{'Home.ShouldConnect'|translate}}</p>
    </div>
</div>
<div class="container pt-5" *ngIf="games && user ">
    <div class="text-center my-games">
        <h2 class="white">{{'Header.MyGames'|translate|uppercase}}</h2>
        <div class="row mb-5">
            <div class="py-3 px-5">
                <form [formGroup]="linkForm">
                    <input *ngIf="!validGame" type="text" class="w-100 card px-3 text-center" placeholder="{{'MyGames.Link'|translate|uppercase}}" formControlName="link">
                    <button *ngIf="validGame" type="text" class="w-100 btn" routerLink="/game/{{gameId}}">{{'MyGames.Join'|translate|uppercase}} {{validGame.sport|uppercase}}-{{validGame.side}}v{{validGame.side}} {{validGame.start|date:'dd/MM-HH:mm'}}</button>
                </form>
            </div>
            <div class="gameCard card col-10 col-lg-5 px-2 my-2 mx-auto" *ngFor="let game of games, let i = index"
                [ngStyle]="{'opacity': now>game.end ? 0.5 : 1}">
                <div class="card-body dark" (click)="openGame(game)" style="cursor: pointer; font-size: small;">
                    <div class="important primary">{{game.sport|uppercase}} - {{game.side}}v{{game.side}}</div>
                    <div *ngIf="fields" class="important primary">{{fields[i]?.name|uppercase}}</div>
                    <div class="important primary">{{'MyGames.Date'|translate}}: {{game.start|date:'dd/MM'}} - {{game.start|date:'HH:mm'}}</div>
                    <div *ngIf="fields">{{fields[i]?.address|uppercase}}, {{fields[i]?.city|uppercase}}</div>
                    <div *ngIf="game.side*2-game.playersId.length == 0">{{'COMPLET'|translate|uppercase}}</div>
                    <div *ngIf="game.side*2-game.playersId.length != 0">{{'MyGames.AvailableSpot'|translate|uppercase}} :
                        {{game.side*2-game.playersId.length}}</div>
                </div>
            </div>
            <div *ngIf="games.length==0">
                {{'MyGames.NoGame'|translate|uppercase}}
            </div>
            <div class="btn newGameBtn" routerLink="/map"><mat-icon>add</mat-icon><span>{{'FieldInfo.CreateGame'|translate|uppercase}}</span></div>
            
        </div>
    </div>
</div>