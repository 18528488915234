import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

  constructor(private translateService: TranslateService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  translate(lng: 'fr'|'en'|'es') {
    this.translateService.use(lng);
    this.dialog.closeAll();
  }

}
