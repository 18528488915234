import { Component, OnInit } from '@angular/core';
import { Game } from '../models/game';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { GamesService } from '../services/games.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isAuth: boolean | undefined;
  user: User | undefined;
  closeHelp = false;
  games: Game[] | undefined;

  constructor(private authService: AuthService, private gamesService: GamesService, private router: Router) { }

  ngOnInit(): void {
    this.authService.isAuth$.subscribe((res) => {
      if (res && !this.user) {
        this.setUser();
      }
    })
  }

  setUser() {
    this.user = this.authService.user;
    this.isAuth = true;
    // this.loadGames();
  }

  authEvent(event: any) {
    this.isAuth = event;
  }

  loadGames() {
    this.gamesService.getFutureGames().then((games) => {
      this.games = games.filter((val, i) => i < 4);
    })
  }

  loginPage() {
    this.authService.lastUrl = window.location.pathname;
    this.router.navigate(['/login']);  
    setTimeout(() => {
      window.location.reload();
    }, 50)  
  }

}
