<div class="card" *ngIf="field" #card>
    <!-- <img class="card-img-top" [src]="field.photoUrl" [alt]="field.name"> -->
    <app-image-with-loading [image]="field.photoUrl" [sport]="field.sport">
    </app-image-with-loading>
    <div class="card-body" [ngClass]="{'foot': isFoot,'basket': isBasket,'foot-basket': isFoot && isBasket}">
        <div class="row d-flex align-items-center">
            <div class="col text-truncate" title="{{field.name}}">
                {{field.name}}
            </div>
        </div>
        <div class="row d-flex align-items-center">
            <div class="col text-truncate" title="{{field.address}}, {{field.city}}">
                {{field.address}}, {{field.city}}
            </div>
        </div>
        <div class="row public-games" *ngIf="futureGames!=0">
            {{'FieldInfo.PublicGames'|translate}} : {{futureGames}}
        </div>
    </div>
</div>