import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Game } from '../models/game';
import { ChatDocService } from '../services/chat-doc.service';
import { GamesService } from '../services/games.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
  gameSelected: Game;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, private gameService: GamesService, private chatDocService: ChatDocService) {
    this.gameSelected = this.data
  }

  ngOnInit(): void { }

  switch(player: any) {
    if (this.gameSelected.players[0].includes(player)) {
      this.gameSelected.players[0] = this.gameSelected.players[0].filter((lad) => lad != player);
      this.gameSelected.players[1].push(player);
    } else {
      this.gameSelected.players[1] = this.gameSelected.players[1].filter((lad) => lad != player);
      this.gameSelected.players[0].push(player);
    }
  }

  save() {
    // this.chatDocService.editGame(this.gameSelected);
    // this.dialog.closeAll()
    this.gameService.updateGame(this.gameSelected).then(() => {
      this.dialog.closeAll()
    }).catch((err) => console.log(err));
  }

}
