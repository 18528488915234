<div class="container">
    <div *ngIf="mailSent" class="text-center">
        <p>{{'Logs.Recover'|translate|uppercase}}</p>
        <a href="#"><button class="btn btn-dark">{{'Logs.Back'|translate|uppercase}}</button></a>
    </div>
    <div *ngIf="confirmLinkSent" class="text-center">
        <p>{{'Logs.Confirm'|translate|uppercase}}</p>
        <a href="#"><button class="btn btn-dark">{{'Logs.Back'|translate|uppercase}}</button></a>
    </div>
    <div *ngIf="!mailSent && !confirmLinkSent" class="mt-5">
        <div *ngIf="!forgotten">


            <!-- <p class="text-center mt-5">{{'Home.Login'|translate|uppercase}}</p> -->
            <form action="onLogin()" [formGroup]="form">
                <div class="text-center" *ngIf="wrongLog">
                    <p>{{'Logs.Incorrect'|translate|uppercase}}</p>
                </div>

                <div class="sso-google text-center">
                    <div id="g_id_onload"
                        data-client_id="275268663789-9nt3eguj3l2qg1qqfg68igq7uqmau9bu.apps.googleusercontent.com"
                        data-callback="handleCredentialResponse" data-login_uri="http://localhost:4200/login"
                        data-auto_prompt="false">
                    </div>
                    <div class="g_id_signin" data-type="standard" data-size="large" data-text="sign_in_with" ></div>
                </div>
                <div>
                    <!-- <p class="my-3">{{'Logs.Or'|translate|uppercase}}</p> -->
                    <p class="text-center mt-5">{{'Home.Login'|translate|uppercase}}</p>
                </div>


                <div class="row my-3" style="position: relative;">
                    <input class="col-12" id="email" type="email" placeholder="{{'Home.Email'|translate}}"
                        [ngClass]="{'invalid': form.get('email')?.invalid&&form.get('email')?.dirty&&form.get('email')?.value}"
                        formControlName="email" name="email" />
                </div>
                <div class="row my-3" style="position: relative;">
                    <input class="col-12" id="password" type="password" placeholder="{{'Home.Password'|translate}}"
                        formControlName="password" name="password" />
                </div>
                <div class="row my-3">
                    <button class="btn btn-dark" (click)="onLogin()"
                        [disabled]="form.invalid">{{'Home.Login'|translate|uppercase}}</button>
                </div>
            </form>

            <div *ngIf="notConfirmed" class="text-center">
                <p>{{'Logs.ShouldConfirm'|translate|uppercase}}</p>
                <button class="btn btn-dark" (click)="sendConfirm()">{{'Logs.SendAgain'|translate|uppercase}}</button>
            </div>
            <div class="text-center">
                <a (click)="forgetPw()" id="forgetPwLink">{{'Logs.Forgot'|translate|uppercase}}</a>
            </div>
        </div>
        <div *ngIf="forgotten">
            <form action="sendMail()" [formGroup]="mailForm">
                <div class="row mt-5">
                    <input class="col-12" id="mail" type="email" placeholder="{{'Home.Email'|translate|uppercase}}"
                        [ngClass]="{'invalid': mailForm.get('mail')?.invalid&&mailForm.get('mail')?.dirty&&mailForm.get('mail')?.value}"
                        formControlName="mail" name="mail" value="mailForm.get('mail').toUppercase()" />
                </div>
                <div class="row mt-3">
                    <button class="btn btn-dark" (click)="sendMail()"
                        [disabled]="mailForm.invalid">{{'Logs.Send'|translate|uppercase}}</button>
                </div>
            </form>
            <div *ngIf="wrongMail" class="text-center">
                <p>{{'Logs.EmailNotPresent'|translate|uppercase}}</p>
            </div>
        </div>
    </div>
</div>