import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {
  link: string = window.location.protocol + '//' + window.location.host  + window.location.pathname;
  copied = false;

  constructor() { }

  ngOnInit(): void {
  }

  copy() {
    navigator.clipboard.writeText(this.link);
    this.copied = true;
  }

}
