import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FieldsService } from 'src/app/services/fields.service';
import { } from 'googlemaps';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Field } from 'src/app/models/field';
import { TranslateService } from '@ngx-translate/core';
import { CropperComponent } from 'src/app/cropper/cropper.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-field',
  templateUrl: './new-field.component.html',
  styleUrls: ['./new-field.component.scss']
})
export class NewFieldComponent implements OnInit {
  createForm: FormGroup;
  imagePreview: string | undefined;
  user: User | undefined;
  address: string[] = []
  file: any;

  constructor(private fieldService: FieldsService,
    private translateService: TranslateService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewFieldComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.createForm = new FormGroup({
      foot: new FormControl('',),
      basket: new FormControl('',),
      side: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      address: new FormControl('', Validators.required),
      photoUrl: new FormControl('')
    })
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user) => {
      this.user = user;
    });
    this.setAddress();
  }

  setAddress() {
    let geo = this.data.address.geometry;
    let add = this.data.address.name;
    let comp = this.data.address.address_components;

    let city = comp.find((el: any) => el.types.includes('locality')) ? comp.find((el: any) => el.types.includes('locality')) : comp.find((el: any) => el.types.includes('administrative_area_level_1'));
    if (geo && add && comp) {
      this.address.push(add);
      this.address.push(geo.location.lat());
      this.address.push(geo.location.lng());
      this.address.push(city.long_name);
      this.createForm.get('address')?.setValue(this.address);
    }
  }

  createField() {
    let formValue: any = this.createForm.value;
    let sports = []
    formValue.foot ? sports.push('foot') : null;
    formValue.basket ? sports.push('basket') : null;

    let pseudo = this.user ? this.user.pseudo : '**undefined**';

    if (!formValue.photoUrl) formValue.photoUrl = ''

    this.fieldService.createField(new Field(sports, formValue.side, formValue.name, formValue.address[0], formValue.address[1], formValue.address[2], formValue.address[3], formValue.photoUrl.name, 'new', pseudo, new Date(), new Date(), true), this.file)
      .then(() => {
        this.dialogRef.close(true);
        this.translateService.get('FieldInfo.SuccessCreateField').subscribe((val) => alert(val));
      })
      .catch((error) => {
        console.log(error);
      })

  }

  login() {
    this.router.navigate(['login']);
  }

  onImagePick(event: Event) {
    this.dialog.open(CropperComponent, { data: { image: event } }).afterClosed().subscribe((res) => {
      this.cropped(res, event)
    })

  }

  cropped(img: any, event: Event) {
    this.createForm.get('photoUrl')?.patchValue(img);
    this.createForm.get('photoUrl')?.updateValueAndValidity();
    this.file = this.createForm.get('photoUrl')?.value;
    this.imagePreview = img;
  }

}
