import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Field } from 'src/app/models/field';
import { GamesService } from 'src/app/services/games.service';

@Component({
  selector: 'app-field-card',
  templateUrl: './field-card.component.html',
  styleUrls: ['./field-card.component.scss']
})
export class FieldCardComponent implements OnInit {

  @Input() field?: Field;
  displayImg = false;

  futureGames: number = 0;


  @ViewChild('card')
  public target?: ElementRef;
  public observer?: IntersectionObserver;

  get isFoot(): boolean {
    let foot = this.field?.sport.includes('foot')
    return foot ? foot : false;
  }
  
  get isBasket(): boolean {
    let basket = this.field?.sport.includes('basket')
    return basket ? basket : false;
  }

  constructor(private gamesService: GamesService) { }

  ngOnInit(): void {
    this.setIntersect();
  }

  getFutureGames() {
    this.gamesService.getGamesByFieldId(this.field?._id).then((games) => {
      this.futureGames = games.length;
    })
  }

  setIntersect() {
    if (this.target) {
      this.observer = new IntersectionObserver((obs) => {
        this.displayImg = obs[0].isIntersecting;
        if (this.displayImg) this.getFutureGames();
      });
      this.observer.observe(this.target.nativeElement)
    } else {
      setTimeout(() => {
        this.setIntersect()
      }, 500)
    }
  }

}
