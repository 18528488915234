import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  user: User | undefined;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.authService.isAuth$.subscribe((res) => {
      if (res && !this.user) {
        this.setUser();
      }
    })
  }

  setUser() {
    this.user = this.authService.user;
  }

  logout() {
    this.authService.logout();
  }

  loginPage() {
    this.router.navigate(['home']);
  }
}
