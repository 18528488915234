import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CropperComponent } from '../cropper/cropper.component';
import { DialogQuestionComponent } from '../dialog-question/dialog-question.component';
import { Field } from '../models/field';
import { Game } from '../models/game';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { BalanceService } from '../services/balance.service';
import { FieldsService } from '../services/fields.service';
import { GamesService } from '../services/games.service';
import { YamatchComponent } from '../yamatch/yamatch.component';

@Component({
  selector: 'app-field-info',
  templateUrl: './field-info.component.html',
  styleUrls: ['./field-info.component.scss']
})
export class FieldInfoComponent implements OnInit {
  field: Field | undefined;
  games: Game[] | undefined;
  creatingGame = false;
  gameCreated = false;
  gameSelected: Game | undefined = undefined;
  teamSide: number[] = [];
  user: User | undefined;
  cantJoin = false;
  copied = false;
  shouldConnect = false;
  freeGame = false;
  lowBalance = false;

  form: FormGroup;
  // Date to format: 'yyyy-MM-ddTHH:mm'
  now: string = new Date().toLocaleDateString().split('/').reverse().join('-') + 'T' + new Date().toLocaleTimeString().split(':').slice(0, 2).join(':');

  constructor(private fieldService: FieldsService,
    private gameService: GamesService,
    private authService: AuthService,
    private translate: TranslateService,
    private balanceService: BalanceService,
    private dialog: MatDialog) {
    this.form = new FormGroup({
      sport: new FormControl('', [Validators.required]),
      side: new FormControl('', [Validators.required]),
      level: new FormControl('', [Validators.required]),
      isPublic: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      price: new FormControl('', [Validators.required]),
      currency: new FormControl('', [Validators.required])
    })
  }

  ngOnInit(): void {
    this.setFieldInfos();
    this.auth();
  }

  auth() {
    this.user = this.authService.user ? this.authService.user : undefined;
  }

  setFieldInfos() {
    this.field = this.fieldService.currentField;
    this.gameService.getGamesByFieldId(this.field?._id)
      .then((games: Game[]) => {
        this.games = games.filter((game) => game.isPublic || game.playersId.includes(this.user ? this.user?.id : '0'));
      })
      .catch((error) => {
        console.log(error);
      })
  }

  copyFieldId(id: string) {
    navigator.clipboard.writeText(id);
    this.copied = true;
  }

  newGame() {
    this.creatingGame = true;
    this.setFreeGame(1);
  }

  backInfo() {
    this.gameCreated = false;
    this.setFieldInfos();
  }

  createGame() {
    if (this.field && this.user) {
      if (this.freeGame) {
        this.validCreateGame();
      } else {
        this.authService.checkBalance(this.user).then((resp) => {
          this.validCreateGame();
        }).catch(() => {
          // NOT ENOUGH CREDITS, BUY SOME
          this.lowBalance = true;
        });
      }
    } else {
      this.shouldConnect = true;
    }
  }

  validCreateGame() {
    let date = this.form.get('date')?.value;
    if (this.field && this.user)
      this.gameService.createGame(new Game(this.form.get('sport')?.value, parseInt(this.form.get('side')?.value), this.field._id, parseInt(this.form.get('level')?.value), new Date(Date.parse(date)), new Date(Date.parse(date)), new Date(Date.parse(date) + 1000 * 60 * 60), false, '', [[], []], [], [], this.form.get('isPublic')?.value == 1, this.user.id, this.form.get('price')?.value, this.form.get('currency')?.value, 'pending', new Date(), new Date(), true))
        .then(() => {
          // UPDATE BALANCE
          if (this.user && !this.freeGame)
            this.balanceService.updateBalanceByPseudo(this.user?.pseudo, -1);
          this.creatingGame = false;
          this.gameCreated = true;
          this.setFieldInfos();
        })
        .catch(() => {
          console.log('ERROR');
          this.setFieldInfos();
        });
  }

  selectGame(game: Game) {
    this.gameSelected = game;
    this.teamSide = new Array(game.side);
    if (this.user) {
      if (game.players[0].includes(this.user?.pseudo) || game.players[1].includes(this.user?.pseudo)) {
        this.cantJoin = true;
      } else {
        this.cantJoin = false;
      }
    }
  }

  joinGame(game: Game, team: number, pseudo: string, id: string) {
    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: this.translate.instant('FieldInfo.BringBall') });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res==true) game.gotBall.push(pseudo);
      game.players[team].push(pseudo);
      game.playersId.push(id);
      this.gameService.updateGame(game)
        .then(() => {
          this.cantJoin = true;
        })
        .catch((error) => {
          console.log(error);
        });
    })
  }

  authEvent(event: any) {
    if (event == true) {
      this.auth();
      this.setFieldInfos();
      if (this.gameSelected) this.selectGame(this.gameSelected);
    }
  }

  setFreeGame(num: number) {
    this.freeGame = num === 1 ? true : false;
    if (this.freeGame) {
      this.form.get('price')?.setValue(0);
      this.form.get('currency')?.setValue('USD');
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

}
