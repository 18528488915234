import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  ready: boolean = false;

  cropperHeight: number = 0;
  cropperWidth: number = 0;

  aspectRatio: number = 16/9;

  constructor(public dialogRef: MatDialogRef<CropperComponent>,@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.image;
    if (this.data.aspectRatio) this.aspectRatio = this.data.aspectRatio;
    this.dialogRef.addPanelClass('question')
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: LoadedImage) {
    // show cropper
    // console.log('imageLoaded', image);
  }
  cropperReady() {
    // cropper ready
    // console.log('cropperReady');
    this.ready = true
  }
  loadImageFailed() {
    // show message
    // console.log('loadImageFailed');

  }

  validate() {
    this.dialogRef.close(this.croppedImage);
  }

}
