import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  langList = ['en', 'es', 'fr'];

  constructor(private translate: TranslateService, private authService: AuthService, private titleService: Title) {
    this.loadTranslations();
    this.auth();
  }

  loadTranslations() {
    let lang = this.translate.getBrowserLang();
    (lang && this.langList.includes(lang)) ? this.translate.setDefaultLang(lang) : this.translate.setDefaultLang('en');
    this.translate.addLangs(this.langList);
  }

  auth() {
    this.authService.getCurrentUser().then((user: any) => {
      this.titleService.setTitle(this.titleService.getTitle() + ' - ' + user?.pseudo);
    });
  }
}
