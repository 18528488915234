import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  validLink = false;
  load = true;
  changeSuccess = false;
  changeForm: FormGroup;
  email: string = '';

  constructor(private emailService: EmailService, private authService: AuthService) {
    this.changeForm = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.emailService.isRecoveryLinkValid(window.location.pathname.substring(window.location.pathname.lastIndexOf('/'))).then((link: any) => {
      if (link.active) {
        this.validLink = true;
        this.email = link.email;
      };
    }).finally(() => this.load = false)
  }

  changePw() {
    this.authService.changePassword(this.email, this.changeForm.get('password')?.value)
      .then(() => this.changeSuccess = true)
      .catch((err) => console.log(err))
  }

}
