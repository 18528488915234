
<div class="container text-center mt-3">
    <div *ngIf="load">
        <p>{{'Logs.Loading'|translate|uppercase}}</p>
    </div>
    <div *ngIf="!load">
        <div *ngIf="!validLink text-center">
            <p>{{'Logs.LinkNotValid'|translate|uppercase}}</p>
        </div>
        <div *ngIf="validLink">
            
            <div *ngIf="!changeSuccess">
                <p>{{email}}</p>
                <form action="changePw()" [formGroup]="changeForm">
                    <div class="row m-3">
                        <input class="col-12 col-sm-10 col-md-8 col-lg-6 mx-auto" id="password" type="password" placeholder="{{'Home.Password'|translate|uppercase}}"
                            formControlName="password" name="password" />
                    </div>
                    <div class="row m-3">
                        <input class="col-10 col-sm-10 col-md-8 col-lg-6 mx-auto" id="confirmPassword" type="password"
                            placeholder="{{'Home.ConfirmPassword'|translate|uppercase}}" formControlName="confirmPassword"
                            name="confirmPassword" />
                    </div>
                    <div class="row m-3">
                        <button class="btn col-12 col-sm-10 col-md-8 col-lg-6 mx-auto" (click)="changePw()"
                            [disabled]="changeForm.invalid || (changeForm.get('password')?.value != changeForm.get('confirmPassword')?.value)">{{'Logs.UpdatePw'|translate|uppercase}}</button>
                    </div>
                </form>
            </div>
            <div *ngIf="changeSuccess" class="w-100 text-center">
                <p>{{email}}</p>
                <p>{{'Logs.UpdatePwSuccess'|translate|uppercase}}</p>
                <p>{{'Logs.SuccessMessage'|translate|uppercase}}</p>
                <a href="#"><button class="btn btn-dark">{{'Logs.Back'|translate|uppercase}}</button></a>
            </div>
        </div>
    </div>
</div>