import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { CropperComponent } from '../cropper/cropper.component';
import { Profile } from '../models/profile';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: Profile | undefined;
  profileForm: FormGroup;
  contactForm: FormGroup;
  reportForm: FormGroup;
  imagePreview: string | undefined;
  loading = true;
  file: any;
  editProfile = false;
  user: User | undefined;
  displayReportForm: boolean = false;
  displayContactForm: boolean = false;
  messageSuccess: boolean = false;
  footLvl: number = -1;
  basketLvl: number = -1;
  reload = false;
  updating = false;

  constructor(private authService: AuthService, private emailService: EmailService, private router: Router, private dialog: MatDialog) {
    this.profileForm = new FormGroup({
      age: new FormControl('', []),
      photoUrl: new FormControl('', []),
      foot: new FormControl('', []),
      basket: new FormControl('', []),
      favoritePos: new FormControl('', []),
      // description: new FormControl('', []),
      city: new FormControl('', [])
    });
    this.contactForm = new FormGroup({
      subject: new FormControl('', [Validators.required]),
      messageContent: new FormControl('', [Validators.required])
    });
    this.reportForm = new FormGroup({
      subject: new FormControl('', [Validators.required]),
      messageContent: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.setProfile(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
    this.authService.getCurrentUser().then((user) => {
      this.user = user;
    });
    this.router.events.subscribe((obs) => {
      if (window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1) == this.user?.pseudo && obs instanceof NavigationEnd) {
        this.displayContactForm = false;
        this.displayReportForm = false;
        this.setProfile(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
      }
    })
  }

  setProfile(pseudo: string) {
    this.reload = true;
    this.authService.getProfileByPseudo(pseudo).then((res: any) => {
      this.profile = res;
      this.reload = false;
      this.updating = false;
      if (this.profile) {
        this.profile.id = res._id;
        this.authService.getUserLvl(this.profile.userPseudo).then(
          (data: any) => {
            this.footLvl = data.foot;
            this.basketLvl = data.basket;
            this.loading = false;
          })
      };
    }).catch(() => { this.loading = false })
  }

  updateProfile() {
    this.updating = true;
    let age = this.profileForm.get('age')?.value;
    let favoritePos = this.profileForm.get('favoritePos')?.value;
    // let description = this.profileForm.get('description')?.value;
    if (age || favoritePos) {
      this.authService.updateProfile(this.profile, age, favoritePos).then(() => {
        this.editProfile = false;
        if (this.profile) this.setProfile(this.profile.userPseudo);
      }).catch((err) => console.log(err));
    }

    let footballLevel = this.profileForm.get('foot')?.value;
    let basketballLevel = this.profileForm.get('basket')?.value;

    if (this.user && (footballLevel || basketballLevel)) {
      this.authService.updateUserLvl(this.user.id, footballLevel, basketballLevel).then(() => {
        this.editProfile = false;
        if (this.profile) this.setProfile(this.profile.userPseudo);
      }).catch((err) => console.log(err));
    }

    if (this.file) {
      if (this.profile)
        this.authService.updateProfilePhoto(this.profile, this.file).then(() => {
          this.editProfile = false;
          if (this.profile) this.setProfile(this.profile.userPseudo);
        }).catch((err) => console.log(err));
    }

    if (this.profileForm.get('city')?.value && this.user) {
      this.authService.updateCity(this.user?.id, this.profileForm.get('city')?.value).then(() => {
        this.editProfile = false;
        this.updating = false;
      })
    }

    this.reloadCard();
  }

  reloadCard() {
    this.reload = true;
    this.profileForm.reset();
    setTimeout(() => {
      this.reload = false
    }, 300)
  }

  array(start: number, end: number) {
    if (start === end) return [start];
    let arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i)
    }
    return arr;
  }

  contact() {
    this.displayReportForm = false;
    this.displayContactForm = true;
  }

  report() {
    this.displayContactForm = false;
    this.displayReportForm = true;
  }

  sendContactMessage() {
    if (this.profile && this.user) {
      this.emailService.sendContactPlayer(this.profile.userPseudo, this.contactForm.get('subject')?.value, this.contactForm.get('messageContent')?.value, this.user?.id)
        .then(() => {
          this.successRequest();
        })
    }
  }

  sendReport() {
    if (this.profile && this.user) {
      this.emailService.sendReportPlayer(this.profile.userPseudo, this.reportForm.get('subject')?.value, this.reportForm.get('messageContent')?.value, this.user?.id)
        .then(() => {
          this.successRequest();
        })
    }
  }

  successRequest() {
    this.messageSuccess = true;
    this.displayContactForm = false;
    this.displayReportForm = false;
    this.reportForm.reset();
    this.contactForm.reset();
    setTimeout(() => {
      this.messageSuccess = false;
    }, 3000)
  }

  onImagePick(event: Event) {
    this.dialog.open(CropperComponent, {data: {image: event, aspectRatio: 1}} ).afterClosed().subscribe((res) => {
      this.cropped(res, event)
    })

  }

  editProfileFn() {
    this.editProfile=!this.editProfile;
  }

  cropped(img: any, event: Event) {
    this.profileForm.get('photoUrl')?.patchValue(img);
    this.profileForm.get('photoUrl')?.updateValueAndValidity();
    this.file = this.profileForm.get('photoUrl')?.value;
    this.imagePreview = img;
  }
}
