import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { } from 'googlemaps';
import { Subject } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { FieldInfoComponent } from '../field-info/field-info.component';
import { Field } from '../models/field';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { FieldsResponse, FieldsService } from '../services/fields.service';
import { MapService } from '../services/map.service';
import { FieldListComponent } from './field-list/field-list.component';
import { NewFieldComponent } from './new-field/new-field.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @ViewChild('maps', { static: true }) mapElement!: any;
  map: google.maps.Map | undefined;
  mapIsSet = false;
  dialogCfg: MatDialogConfig | undefined;
  addField = false;
  markers: google.maps.Marker[] = [];
  originalMarkers: google.maps.Marker[] = [];
  confirmFieldPosition: boolean = false;
  newAddress: any;
  incorrectAddress: boolean = false;
  tooClose: boolean = false;
  user: User | undefined;

  boundsChanged: Subject<boolean> = new Subject();

  initLat = 13.9372893;
  initLng = 18.0845259;

  constructor(private dialog: MatDialog, private fieldsService: FieldsService, private authService: AuthService, private mapService: MapService) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user) => {
      this.user = user;
      this.user ? this.setCityPosition(this.user) : null;
    });
    this.waitForGoogle(300);
    this.initBoundsListener();
  }

  waitForGoogle(ms: number) {
    setTimeout(() => {
      if (!this.mapIsSet && google) {
        this.setMap(this.initLat, this.initLng);
        this.setEvents();
        this.setDialogConfig();
      } else {
        this.waitForGoogle(500);
      }
    }, ms)
  }

  initBoundsListener() {
    this.boundsChanged.pipe(
      debounceTime(500)
    ).subscribe((res) => {
      this.setEvents();
    })
  }

  setCityPosition(user: User) {
    this.mapService.getUserCityPosition(user).then((res: any) => {
      if (res.results[0]) {
        let loc = window.location.pathname.match(/[0-9-]+.[0-9]+/g);
        if (loc) {
          let lat = loc[loc.length - 2];
          let lng = loc[loc.length - 1];

          this.setMap(parseFloat(lat), parseFloat(lng), 15);
        } else {
          this.setMap(res.results[0].geometry.location.lat, res.results[0].geometry.location.lng);
        }
        this.setEvents();

      } else {
        this.setMap(this.initLat, this.initLng, 2.8)
      }
    }).catch((error) => {
      console.log('error', error);
    })
  }

  setMap(lat: number | undefined, lng: number | undefined, zoom?: number) {
    const mapProperties = {
      center: new google.maps.LatLng(lat ? lat : this.initLat, lng ? lng : this.initLng),
      zoom: zoom ? zoom : this.user ? 12 : 2.8,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    this.mapIsSet = true;

    this.map?.addListener('bounds_changed', () => {
      this.boundsChanged.next(true)
    })
  }

  setEvents() {
    setTimeout(() => {
      let neLat = this.map?.getBounds()?.getNorthEast().lat().toString();
      let neLng = this.map?.getBounds()?.getNorthEast().lng().toString();
      let swLat = this.map?.getBounds()?.getSouthWest().lat().toString();
      let swLng = this.map?.getBounds()?.getSouthWest().lng().toString();
      // TODO: getFieldLatLngs
      this.fieldsService.getFields(neLat, neLng, swLat, swLng).then((fieldsResponse: FieldsResponse) => {
        this.originalMarkers = [];
        this.setMarkers(fieldsResponse.fields);
        
      });
    }, 300)

  }

  haversine_distance(mk1: any, mk2: any) { // mk1 new Marker - mk2 Marker that already exists
    var R = 3958.8; // Radius of the Earth in miles
    var rlat1 = mk1.location.lat() * (Math.PI / 180); // Convert degrees to radians
    var rlat2 = mk2.position.lat() * (Math.PI / 180); // Convert degrees to radians
    var difflat = rlat2 - rlat1; // Radian difference (latitudes)
    var difflon = (mk2.position.lng() - mk1.location.lng()) * (Math.PI / 180); // Radian difference (longitudes)

    var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
    return d;
  }

  setMarkers(activeFields: Field[]) {
    let icon = {
      url: '',
      scaledSize: new google.maps.Size(50, 50), // scaled size
    }
    activeFields.forEach((field) => {
      if (field.sport.includes('foot')) {
        field.sport.includes('basket') ? icon.url = '../../assets/icons/footbasketball-full.png' : icon.url = '../../assets/icons/football-full.png'
      } else {
        icon.url = '../../assets/icons/basketball-full.png'
      }
      let marker = new google.maps.Marker({
        position: { lat: field.lat, lng: field.lng },
        map: this.map,
        title: field._id,
        icon: icon,
      });
      this.originalMarkers.push(marker);

      marker.addListener('click', () => {
        this.originalMarkers.forEach((gm_marker) => {
          if (gm_marker.getTitle() != marker.getTitle()) gm_marker.setOpacity(0.2);
        })
        this.fieldsService.setCurrentField(field);
        this.openFieldInfos(field._id)
      });

    });
  }

  openFieldInfos(id: string | null | undefined) {
    this.setDialogConfig();
    if (id) {
      const dialogRef = this.dialog.open(FieldInfoComponent);
      dialogRef.updatePosition(this.dialogCfg?.position);
      dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

      dialogRef.afterClosed().subscribe(() => {
        this.originalMarkers.forEach((gm_marker) => {
          gm_marker.setOpacity(1);
        })
      })
    }
  }

  openAddField() {
    this.setDialogConfig();
    const dialogRef = this.dialog.open(NewFieldComponent, { data: { address: this.newAddress } });
    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe((resp) => {
      this.cancelFieldCreation(!resp);
    })
  }

  completeAddress(event: any) {
    if (event.target.value.length >= 3) {
      this.autoComplete();
    }
  }

  autoComplete(): any {
    const input = document.getElementById("address") as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    const onChange = searchBox.addListener("places_changed", () => {
      let places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      this.markers.forEach((marker) => {
        marker.setMap(null);
      });
      this.markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return;
        }

        let map = this.map;
        // Create a marker for each place.
        this.markers.push(
          new google.maps.Marker({
            map,
            // icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.newAddress = places[0];

      this.map?.fitBounds(bounds);

      if (places[0].address_components) {
        this.tooClose = false;
        this.originalMarkers.forEach((existingMarker) => {
          if (this.haversine_distance(places[0].geometry, existingMarker) < 0.015) {
            this.tooClose = true;
          }
        })
        if (places[0].address_components.find((el: any) => el.types.includes('locality') || el.types.includes('administrative_area_level_1'))) {
          this.incorrectAddress = false;
          this.confirmFieldPosition = true;
        } else {
          this.incorrectAddress = true;
          this.confirmFieldPosition = false;
        }
      } else {
        this.incorrectAddress = true;
        this.confirmFieldPosition = false;
      }

      onChange.remove();
    });
  }

  setDialogConfig() {
    let cfg = new MatDialogConfig();
    cfg.width = '320px';
    cfg.height = '100%';
    cfg.position = { right: '0', bottom: '0' };
    this.dialogCfg = cfg;
  }

  cancelFieldCreation(andSet?: boolean) {
    this.addField = false;
    this.tooClose = false;
    this.confirmFieldPosition = false;
    andSet && this.user ? this.setCityPosition(this.user) : null;
    this.setEvents();
  }

  openFields() {
    const dialogRef = this.dialog.open(FieldListComponent);

    this.setDialogConfig();
    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe((id) => {
      this.searchFieldId(id);
    })
  }

  goToField(fieldId: string) {
    this.fieldsService.getFieldById(fieldId).then((field) => {
      if (field) {
        this.fieldsService.setCurrentField(field);
        this.setMap(field.lat, field.lng, 15);
        this.setEvents();
        this.openFieldInfos(fieldId);
      }
    })
  }

  searchFieldId(id?: any) {
    this.addField = false;
    this.confirmFieldPosition = false;
    if (id) {
      this.goToField(id)
    }

  }

}
