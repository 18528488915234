import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-with-loading',
  templateUrl: './image-with-loading.component.html',
  styleUrls: ['./image-with-loading.component.scss']
})
export class ImageWithLoadingComponent implements OnInit {

  @Input() loader: string = '../../../../assets/icons/soccer-load.svg';
  @Input() image?: string;
  @Input() sport?: string[];
  @Input() profile?: boolean;

  defaultImg: string = '';

  isLoading: boolean;

  constructor() {
    this.isLoading = true;    
  }

  ngOnInit() {
    this.defaultImg = this.setDefaultImg();
  }

  hideLoader() {
    this.isLoading = false;
  }

  setDefaultImg(): string {
    if (this.profile) return '../../assets/images/USER.jpg'
    if (this.sport) {      
      if (this.sport.includes('foot')) {
        return this.sport.includes('basket') ?  '../../assets/images/MIX_COURT.jpg' :  '../../assets/images/SOCCER_COURT.png'
      } else {
        return  '../../assets/images/BASKET_COURT.jpg'
      }
    } else {
      return '../../assets/images/MIX_COURT.jpg';
    }

  }

}
