import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from '../models/profile';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  profile: Profile | undefined;
  currencies: string[] = [];
  // currencies: string[] = ['1EUR', '1USD', '1GBP', '20MXN', '1.3CAD'];
  currency: string | undefined;
  quantities: number[] = [1, 5, 10, 20, 50];
  quantity: number | undefined;
  priceConfirmed = false;
  price: number | undefined;
  curr: string | undefined;
  approved: boolean | undefined;

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.auth.getCreditPrice().then((price) => {
      this.currencies = [price + 'USD']
      this.auth.getProfileById(window.location.pathname.replace('/addCoins/', '')).then((profile: any) => {
        this.profile = profile;
        if (this.profile) this.profile.id = profile._id;
        this.addCurr(this.currencies[0]);
      })
    })
  }

  addQtt(num: number) {
    this.quantity = num;
    this.setPrice();
    this.scrollDown();
  }

  addCurr(cur: string) {
    this.currency = cur;
    this.setPrice();
  }

  setPrice() {
    this.priceConfirmed = false;
    if (this.currency && this.quantity) {
      let regex = /[0-9|.]+/gi
      let arr = this.currency.match(regex);
      if (arr) {
        this.price = this.quantity * parseFloat(arr[0]);
        this.curr = this.currency.match(/[A-Z]+/gi)?.[0]
      }
    }
  }

  validTransaction(event: any) {
    if (event && this.quantity && this.profile) {
      this.auth.updateBalance(this.profile?.balance + this.quantity, this.profile?.id).then(() => {
        this.router.navigate(['/profile/' + this.profile?.userPseudo])
      }).catch((err) => {
        if (event && err) console.log('Problème lors de l\'ajout de vos crédits', err); // updateBalance failed
      })
    } else {
      this.approved = event;
    }
  }

  scrollDown() {
    setTimeout(() => {
      window.scrollTo({ top: 9000, behavior: 'smooth' })
    }, 100)
  }

  reinitialize() {
    this.approved = undefined;
  }

}
