import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-question',
  templateUrl: './dialog-question.component.html',
  styleUrls: ['./dialog-question.component.scss']
})
export class DialogQuestionComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string, public dialogRef: MatDialogRef<DialogQuestionComponent>) {
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('100vw', '100vh');

    this.dialogRef.addPanelClass('question')
  }

  validate() {
    this.dialogRef.close(true);
  }

}
