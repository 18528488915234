import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  forgotten: boolean = false;
  mailSent: boolean = false;
  form: FormGroup;
  mailForm: FormGroup;
  mailServiceResponse: string = '';
  wrongLog = false;
  notConfirmed = false;
  confirmLinkSent = false;
  wrongMail = false;
  @Output() isAuth = new EventEmitter();
  isConnected = false;

  authSubscription = new Subscription();

  constructor(private auth: AuthService, private emailService: EmailService, private router: Router) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)])
    });
    this.mailForm = new FormGroup({
      mail: new FormControl('', [Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(50)])
    })
  }

  ngOnInit() {
    this.initAuth();
    this.initRoute();
  }

  initAuth() {
    this.authSubscription = this.auth.isAuth$.subscribe((val) => {
      if (val) this.router.navigate(['/']);
    });

    setTimeout(() => {
      if (!this.auth.isAuth$.value && this.auth.hasToken()) {
        this.auth.loginWithGoogle()
          .then(() => {
            this.router.navigate(['/'])
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }, 1000)
  }

  initRoute() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate' && event.url.includes('login')) {
          // le composant est issu d'un retour navigateur
          window.location.reload();
        }
      }
    });
  }

  onLogin() {
    if (this.form.valid) {
      this.wrongLog = false;
      this.auth.login(
        this.form.get('email')?.value.toLowerCase(),
        this.form.get('password')?.value
      ).then(
        (existingAccount: any) => {
          if (existingAccount.active) {
            this.auth.isAuth$.subscribe((res: any) => {
              if (!this.isConnected) {
                this.isAuth.emit(res);
                this.router.navigate(['home']);
                this.isConnected = true;
              }
            })
          } else {
            this.notConfirmed = true;
          }
        }
      ).catch(
        () => {
          this.wrongLog = true;
        }
      );
    }
  }

  forgetPw() {
    this.forgotten = true;
  }

  sendMail() {
    this.wrongMail = false;
    // check if mail is present  
    this.auth.isMailPresent(this.mailForm.get('mail')?.value.toLowerCase()).then(() => {
      this.auth.sendMailTo(this.mailForm.get('mail')?.value.toLowerCase()).then((res: any) => {
        this.mailServiceResponse = res.message;
        this.mailSent = true;
      }).catch(() => console.log('Mail non valide'));

    }).catch((err) => {
      console.log(err);
      
      this.wrongMail = true;
    });
  }

  sendConfirm() {
    this.emailService.sendConfirmLink(this.form.get('email')?.value.toLowerCase()).then((res: any) => {
      this.mailServiceResponse = res.message;
      this.confirmLinkSent = true;
    }).catch((err) => console.log(err))
  }

  ngOnDestroy(): void {
    this.authSubscription.unsubscribe();
  }
}
