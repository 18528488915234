import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';
import { CguComponent } from './cgu/cgu.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  form: FormGroup;
  subscribeSuccess: boolean = false;
  errorMessage: string | undefined;
  regex = new RegExp('([a-z]|[0-9])+', 'gi');

  constructor(private auth: AuthService, private translateService: TranslateService, private emailService: EmailService, private dialog: MatDialog) {
    // let regex = new RegExp('([a-z]|[0-9])+', 'gi')
    this.form = new FormGroup({
      pseudo: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(15), Validators.pattern(this.regex)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.minLength(4), Validators.maxLength(50)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      city: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(25)])
    })
  }

  cleanPseudoValue(event: any) {
    if (event.target.value.match(/\W|_/g)) {
      this.form.get('pseudo')?.setValue(event.target.value.replace(/\W/g, '').replace('_', ''));
    } else if (event.code == 'Backspace' && this.form.get('pseudo')?.value.length < 15 && this.form.get('pseudo')?.value.length > 0) {
      this.form.controls['pseudo']?.setErrors(null)
    }
  }

  open(display: 'cgu'|'cgv'|'cookies') {
    this.dialog.open(CguComponent, {data: display});
  }

  onSignup() {
    if (this.form.valid && this.form.get('pseudo')?.value.length < 15) {
      this.errorMessage = undefined;
      this.auth.createNewUser(
        this.form.get('pseudo')?.value.replace(/\W/g, '').replace('_', ''),
        this.form.get('email')?.value,
        this.form.get('password')?.value,
        this.form.get('city')?.value, -1, -1
      ).then(
        (res) => {
          this.emailService.sendConfirmLink(this.form.get('email')?.value).then(() => {
            this.subscribeSuccess = true;
          }).catch(
            () => {
              this.translateService.get('Logs.SendingError').subscribe((error) => {
                this.errorMessage = error;
              })
            });
        }
      ).catch(
        () => {
          this.translateService.get('Logs.ExistingEmail').subscribe((error) => {
            this.errorMessage = error;
          });
        }
      );
    } else {
      this.form.reset();
    }
  }
}
