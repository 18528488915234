import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CropperComponent } from '../cropper/cropper.component';
import { DialogQuestionComponent } from '../dialog-question/dialog-question.component';
import { Field } from '../models/field';
import { AuthService } from '../services/auth.service';
import { FieldsService } from '../services/fields.service';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
  private admins: string[] = [];
  protected access = false;
  fields: Field[] | undefined;
  selectedField: Field | undefined;
  startEdit = false;
  startDelete = false;
  searchForm: FormGroup;
  editForm: FormGroup;
  imagePreview: string | undefined;
  file: any;
  newSearch = '';

  constructor(private authService: AuthService, private fieldService: FieldsService, private router: Router, private dialog: MatDialog) {
    this.searchForm = new FormGroup({
      // search: new FormControl(''),
      display: new FormControl('')
    });

    this.editForm = new FormGroup({
      name: new FormControl(''),
      city: new FormControl(''),
      address: new FormControl(''),
      photoUrl: new FormControl(''),
      lat: new FormControl(''),
      lng: new FormControl(''),
      basket: new FormControl(''),
      foot: new FormControl(''),
      max: new FormControl(''),
      status: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.authService.getAdmins().then((res: any) => {
      this.admins = res;      
      this.setUser();
    });
  }

  setUser() {
    this.authService.getCurrentUser().then((user: any) => {
      if (!this.admins.includes(user.id)) {
        this.router.navigate(['#'])
      } else {
        this.access = true;
      }
    }).catch(() => {
      this.router.navigate(['#'])
    })
  }

  search() {
    if (this.newSearch) {
      this.fieldService.getFieldsBySearch(this.newSearch.toLowerCase())
        .then((fields: any) => {
          this.fields = fields;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  display() {
    if (this.searchForm.get('display')?.value) {
      this.fieldService.getNLastFields(this.searchForm.get('display')?.value)
        .then((fields: any) => {
          this.fields = fields;
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  selectField(id: string) {
    if (this.fields)
      this.selectedField = this.fields[this.fields.findIndex((field) => field._id === id)]
  }

  back() {
    this.selectedField = undefined;
    this.file = undefined;
    this.imagePreview = undefined;
    this.editForm.reset();
  }

  edit() {
    if (this.selectedField) {
      this.file ? this.fieldService.updateField(this.selectedField, this.file) : this.fieldService.updateField(this.selectedField);
    }
  }

  delete() {
    const dialogRef = this.dialog.open(DialogQuestionComponent, { data: 'Etes vous sur de vouloir supprimer ce terrain et les parties associées ?' });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res == true && this.selectedField) {
        this.fieldService.deleteField(this.selectedField).then(() => {
          window.location.reload();
        })
      }
    })
  }

  onImagePick(event: Event) {
    this.dialog.open(CropperComponent, {data: {image: event}} ).afterClosed().subscribe((res) => {
      this.cropped(res, event)
    })

  }

  cropped(img: any, event: Event) {
    this.editForm.get('photoUrl')?.patchValue(img);
    this.editForm.get('photoUrl')?.updateValueAndValidity();
    this.file = this.editForm.get('photoUrl')?.value;
    this.imagePreview = img;
  }

  setSport() {
    let sports = []
    if (this.editForm.get('foot')?.value) sports.push('foot');
    if (this.editForm.get('basket')?.value) sports.push('basket');
    if (this.selectedField) this.selectedField.sport = sports;
  }

}
