<div class="container text-center mt-3">
    <div *ngIf="email">
        <div *ngIf="load">
            <p>{{'Logs.Loader'|translate|uppercase}}</p>
        </div>
        <div *ngIf="!load">
            <div *ngIf="!confirmed">
                <button class="btn btn-dark" (click)="confirmAccount()">{{'Logs.ConfirmThis'|translate|uppercase}}{{email}}</button>
            </div>
            <div *ngIf="confirmed" class="text-center">
                <p>{{'Logs.ConfirmedAccount'|translate|uppercase}}</p>
                <a (click)="loginPage()"><button class="btn btn-dark">{{'Logs.Back'|translate|uppercase}}</button></a>
            </div>
        </div>
    </div>
    <div *ngIf="!email">
        <p>{{'Logs.LinkNotValid'|translate|uppercase}}</p>
    </div>
</div>