export class Game {
    _id!: string;
    sport!: string;
    side!: number;
    fieldId!: string;
    level!: number;
    date!: Date;
    start!: Date;
    end!: Date;
    activeChat!: boolean;
    gamePassword!: string;
    players!: string[][];
    playersId!: string[];
    gotBall!: string[];
    isPublic!: boolean;
    admin!: string;
    price!: number;
    currency!: string;
    status!: string;
    creationDate!: Date;
    modificationDate!: Date;
    active!: boolean;

    constructor(sport: string, side: number, fieldId: string, level: number,date: Date, start: Date, end: Date, activeChat: boolean, gamePassword: string, players: string[][], playersId: string[], gotBall: string[], isPublic: boolean, admin: string, price: number, currency: string, status: string, creationDate: Date, modificationDate: Date, active: boolean) {
        this.sport = sport;
        this.side = side;
        this.fieldId = fieldId;
        this.level = level;
        this.date = date;
        this.start = start;
        this.end = end;
        this.activeChat = activeChat;
        this.gamePassword = gamePassword;
        this.status = status;
        this.players = players;
        this.playersId = playersId;
        this.gotBall = gotBall;
        this.isPublic = isPublic;
        this.admin = admin;
        this.price = price;
        this.currency = currency;
        this.creationDate = creationDate;
        this.modificationDate = modificationDate;
        this.active = active;
    }
}