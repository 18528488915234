<div class="ref">
    <div style="width:100%;height: 100%;">
        <div id="maps" #maps style="width:100%;height:100%"></div>
        <div id="addButton" #addButton *ngIf="map && !addField"><button class="mapBtn btn btn-secondary"
                (click)="addField=true">{{'Map.AddField'|translate|uppercase}}</button></div>
        <div id="fieldListBtn" #fieldListBtn *ngIf="map"><button class="mapBtn btn btn-secondary"
                (click)="openFields()">{{'Map.FieldList'|translate|uppercase}}</button></div>
        <input type="text" id="address" #searchBox *ngIf="addField" name="searchBox"
            placeholder="{{'Map.Address'|translate}}" (keyup)="completeAddress($event)"
            [ngClass]="{'incorrect': incorrectAddress}" />
        <div id="confirm" *ngIf="confirmFieldPosition && !tooClose">
            <button class="mapBtn btn btn-secondary"
                (click)="openAddField()">{{'Map.ConfirmPosition'|translate|uppercase}}</button>
            <button class="mapBtn btn btn-secondary mx-3"
                (click)="cancelFieldCreation(true)">{{'Map.Cancel'|translate|uppercase}}</button>
        </div>
        <div id="confirm" *ngIf="tooClose">
            <button class="mapBtn btn btn-secondary"
                (click)="cancelFieldCreation(true)">{{'Map.Cancel'|translate|uppercase}}</button>
            <button class="mapBtn btn btn-secondary mx-3" disabled>{{'Map.TooClose'|translate|uppercase}}</button>

        </div>
        <div id="loader" #loader *ngIf="!map">
            <p>{{'Map.Loader'|translate|uppercase}}</p>
        </div>
    </div>
</div>