<div *ngIf="pseudo" class="profileCard text-center mx-auto mt-5" style="width: 316px;height: 500px;">
    <div class="row">
        <div class="col mt-3">
            {{profile?.userPseudo|uppercase}}<span *ngIf="profile?.age">, {{profile?.age}} </span>
        </div>
    </div>
    <div class="row">
        <div class="col d-flex justify-content-center align-items-center" style="height: 220px; overflow: hidden;">
            <div class="profileImg" style="width: 180px; height: 180px;">
                <!-- <img *ngIf="profile?.photoUrl!=''" src="{{profile?.photoUrl}}" alt="" width="220px"> -->
                <!-- <div *ngIf="profile?.photoUrl==''">NO PHOTO</div> -->
                <!-- <div *ngIf="profile?.photoUrl!=''" class="m-auto" style="background-image: url('{{profile?.photoUrl}}');
                                                                background-size: cover; padding-top: 56.25%;width: 220px;"> -->

                <app-image-with-loading [image]="profile?.photoUrl" [profile]="true">
                </app-image-with-loading> 
        </div>
    </div>
</div>
<div class="row text-start px-3">
    <div class="col col-12">{{'Home.City'|translate}}: {{user?.city}}</div>
    <div class="col col-12">{{'Profile.FootLvl'|translate}}: <span *ngFor="let star of array(1,user?.footballLevel)"
            class="stars">
            <img src="../../assets/icons/etoile.png" class="star" alt="level: {{user?.footballLevel}}"
                style="width: 24px;" />
        </span><span *ngIf="user?.footballLevel==-1">-</span></div>
    <div class="col col-12">{{'Profile.BasketLvl'|translate}}: <span *ngFor="let star of array(1,user?.basketballLevel)"
            class="stars">
            <img src="../../assets/icons/etoile.png" class="star" alt="level: {{user?.basketballLevel}}"
                style="width: 24px;" />
        </span><span *ngIf="user?.basketballLevel==-1">-</span></div>
    <div class="col col-12">{{'Profile.FavPos'|translate}}: <span
            *ngIf="profile?.favoritePos">{{profile?.favoritePos}}</span><span *ngIf="!profile?.favoritePos">-</span>
    </div>
</div>
</div>