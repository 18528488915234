<div class="container-fluid p-0 list" *ngIf="!loading">
    
    <input type="text" (keyup)="search($event)" class="w-100" placeholder="{{'FieldInfo.SearchField'|translate}}"> 
    <p class="mt-3 small white" *ngIf="displayFields.length!=0">{{'Map.DisplayedFields'|translate}} : {{displayFields.length + '/' + maxCount}}</p>
    <div class="p-5 small white text-center" *ngIf="displayFields.length==0">{{'MyGames.NoResult'|translate|uppercase}}</div>

    <div class="fieldCard white my-3" *ngFor="let field of displayFields" (click)="goToFieldId(field._id)">
        <app-field-card [field]="field"></app-field-card>
    </div>
</div>
<div *ngIf="loading" class="white">
    {{'Logs.Loading'|translate|uppercase}}
</div>