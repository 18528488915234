<div class="container py-5">
    <!-- <img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg1">
    <img src="../../assets/icons/but_tr.png" alt="goalImg" class="goalImg" id="goalImg2"> -->
    <div class="row">
        <div class="row">
            <div class="profile text-center" *ngIf="user">
                <div class="col">{{'Footer.Hello'|translate|uppercase}} {{user?.pseudo|uppercase}}</div>
            </div>

        </div>
        <div class="row my-5 d-flex flex-column justify-content-around">
            <div *ngIf="user" class="col-10 col-md-5 btn p-3 my-3 mx-auto" routerLink="/mygames">{{'Home.JoinGame'|translate|uppercase}}</div>
            <div class="col-10 col-md-5 btn p-3 my-3 mx-auto" routerLink="/map">{{'FieldInfo.CreateGame'|translate|uppercase}}</div>
            <div class="col-10 col-md-5 btn p-3 my-3 mx-auto" routerLink="/howtoplay">{{'Header.HowToPlay'|translate|uppercase}}</div>
            <div *ngIf="!user" class="col-10 col-md-5 btn p-3 my-3 mx-auto" (click)="loginPage()">{{'Home.Login'|translate|uppercase}}</div>
        </div>
        <div class="row">
            <app-social></app-social>
        </div>
    </div>
</div>