<div class="container white" style="min-width: 240px;" *ngIf="gameSelected">
    <div class="row">
        <div class="col-6 d-flex flex-column">
            <!-- <span class="text-center">{{'MyGames.BrightTeam'|translate|uppercase}}</span> -->
            <div *ngFor="let player of gameSelected.players[0]" class="player d-flex" (click)="switch(player)">
                <div>{{player}}</div>
                <mat-icon>arrow_right</mat-icon>
            </div>
        </div>
        <div class="col-6 d-flex flex-column">
            <!-- <span class="text-center">{{'MyGames.DarkTeam'|translate|uppercase}}</span> -->
            <div *ngFor="let player of gameSelected.players[1]" class="player d-flex flex-row-reverse" (click)="switch(player)">
                <div>{{player}}</div>
                <mat-icon>arrow_left</mat-icon>
            </div>
        </div>
    </div>
    <div class="row mt-5 text-center">
        <button class="btn mx-1" (click)="save()"
            [disabled]="(gameSelected.players[0].length > gameSelected.side) || (gameSelected.players[1].length > gameSelected.side)">{{'Profile.Confirm'|translate|uppercase}}</button>
    </div>
</div>