import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  @Input()
  price: number | undefined;
  @Input()
  currency: string | undefined;
  @Input()
  profileId: string | undefined;
  @Input()
  quantity: number | undefined;

  @Output()
  approved: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('paypalRef', { static: true }) private paypalRef: ElementRef | undefined;

  constructor(private authService: AuthService) { }

  
  ngOnInit(): void {
    this.setPaypalButtons();    
  }

  setPaypalButtons() {
    window.paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'black',
        shape: 'pill',
        label: 'paypal'
      },
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                value: this.price,
                currency_code: 'USD'
              }
            }
          ]
        })
      },
      onApprove: (data: any, actions: any) => {
        return actions.order.capture().then((tr: any) => {
          this.approved.emit(true);
        })
        .catch((err: any) => {
          this.approved.emit(false);
          console.log(err);
        });
      },
      onError: (error: any) => {
        console.log(error);
      }
    }).render(this.paypalRef?.nativeElement);
  }

}