import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChatDoc } from '../models/chat-doc';
import { Game } from '../models/game';


export class ChatDocContent {
  sender!: string;
  msg!: string;
  date!: Date;
  visible!: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ChatDocService {
  constructor(private http: HttpClient) {
  }

  getChatByGameId(gameId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/chatDoc/' + gameId).subscribe((chat) => {
        resolve(chat)
      }, (err) => reject(err));
    })
  }

  createChatDoc(gameId: string) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.protocol + '://' + environment.path + '/api/chatDoc/' + gameId, {}).subscribe((chat) => {
        resolve(chat)
      }, (err) => reject(err));
    })
  }

  updateChatDoc(doc: ChatDoc, newContent: ChatDocContent) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.protocol + '://' + environment.path + '/api/chatDoc/' + doc.gameId, newContent).subscribe((newDoc) => {
        resolve(newDoc)
      }, () => reject())
    })
  }
  
  resetChatDoc(doc: ChatDoc) {
    return new Promise((resolve, reject) => {
      this.http.put(environment.protocol + '://' + environment.path + '/api/chatDoc/reset/' + doc.gameId, {}).subscribe((newDoc) => {
        resolve(newDoc)
      }, () => reject())
    })
  }
}