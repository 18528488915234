<div class="row text-center">
    <div class="col-12" *ngIf="!signup">
        <app-login (isAuth)="authEvent($event)"></app-login>
    </div>
    <div class="col-12" *ngIf="signup">
        <app-signup></app-signup>
    </div>
    <div class="mt-3">
        <a *ngIf="signup" (click)="signup=!signup">{{'Home.Login'|translate|uppercase}}</a>
        <a *ngIf="!signup" (click)="signup=!signup">{{'Home.Signup'|translate|uppercase}}</a>
    </div>
</div>